/* stylelint-disable color-hex-length */
/* stylelint-disable scss/dollar-variable-empty-line-before */
/* stylelint-disable scss/dollar-variable-colon-space-after */

/**
*  COLORS FOR HXP
*/

$color-coral:           #CE5143FF; // hsl(006, 059%, 054%);
$color-mint:            #C7D9C8FF; // hsl(123, 019%, 082%);
$color-off-mint:        #8FC1BBFF; // hsl(173, 029%, 066%);
$color-dark-green:      #1E3D3EFF; // hsl(182, 035%, 018%);
$color-light-tan:       #F7F6F1FF; // hsl(050, 027%, 096%);
$color-sand:            #E1DFD4FF; // hsl(051, 018%, 086%);
$color-tan:             #C4B197FF; // hsl(035, 028%, 068%);
$color-white:           #FEFFFDFF; // hsl(090, 100%, 100%);
$color-orange-cream:    #FFC58FFF; // hsl(029, 100%, 078%);

$color-red:             #CE5142FF;
$color-blue:            #5972B7FF;
$color-blue-grey:       #8A94ADFF;
$text-button-mint:      #33B7A7FF;
$color-grey-b1:         #B1B1B1FF;

$color-link:            #57C3FAFF;

$color-tuscan-red:      #8A4D4DFF; /* hsl( 000, 044, 054 ) brick red    */
$color-dark-coral:      #CE5142FF; /* hsl( 006, 068, 081 ) = HXP Red    */
$color-error:           #D7612FFF;
$color-mac-n-cheese:    #FFC58FFF; /* hsl( 029, 044, 100 ) = Creamsicle */
$color-khaki:           #C4B197FF; /* hsl( 035, 028, 068 ) = Tan        */
$color-bronze:          #A59E5EFF; /* hsl( 054, 043, 065 ) = Goldenrod  */
$color-raw-umber:       #797143FF; /* hsl( 051, 045, 047 ) New Tan      */
$color-mint:            #C7D9C8FF; /* hsl( 123, 008, 085 ) = Mint       */
$color-drk-sea-green:   #99BA9AFF; /* hsl( 122, 018, 073 ) mint shadow  */
$color-deep-moss-green: #3A583BFF; /* hsl( 124, 034, 035 ) Green        */
$color-cambridge-blue:  #8FC1BBFF; /* hsl( 173, 026, 076 ) Off Mint     */
$color-midnight-green:  #1E3D3EFF; /* hsl( 189, 081, 028 ) green dark   */
$color-cool-grey:       #8A94ADFF; /* hsl( 223, 020, 068 ) grey blue    */
$color-denim:           #5972B7FF; /* hsl( 224, 051, 072 ) = Blue       */

$color-nearly-white:    #FEFFFDFF; /* hsl( 090, 001, 100 ) nearly white */
$color-cultred:         #F7F6F1FF; /* hsl( 050, 002, 097 ) Cloud        */
$color-input-grey:      #F4F4F4FF; /* hsl( 000, 000, 096 ) Input Grey   */
$color-platinum:        #E4E4E4FF; /* hsl( 359, 000, 089 ) Outline      */
$color-rich-black:      #000013FF; /* hsl( 000, 000, 000 ) nearly black */
$color-black:           #000000FF;
$color-card-shadow:     #0000000D;
$color-shadow-soft:     #00000024;
$color-shadow-popup:    #00000044;

$color-primary:   $color-dark-coral;
$color-secondary:      $color-denim;
$color-tertiary:       $color-khaki;


$mobile-width:          500px;
$shadow-soft:  0 6px 18px 0 $color-shadow-soft;

$border-1-b1: 1px solid $color-grey-b1;

$box-shadow-0-4-6-0: 0 4px 6px 0 $color-shadow-popup;

// Added Nov 15 and later

// $color-gray-a3: #A3A3A3FF; use gray-b1 instead

$navbar-padding: 2rem;
$builder-info-height: 18rem;

.builder-navbar{
	z-index:10;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	width: 100%;
	height: calc(100% - 2rem);
	padding-right: 0;
}

.builder-navbar .nav-header{
	position:relative;
	display:flex;
	flex-direction: column;
	width: calc(100% - 2 * $navbar-padding);
	height: 14rem;
	padding: $navbar-padding;
	color: $color-white;
	background-image: url('@/resources/nav-header.svg');
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;

	.hxp-logo{
		display:flex;
		align-items: flex-start;
		justify-content: flex-start;
		width: 100%;
		height: 100%;

		svg{
			height:100%;
		}
	}
}


.builder-navbar .nav-content{
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	height: calc(100% - 18rem);
}

.builder-navbar .nav-content .builder-info-wrapper{
	position: relative;
	box-sizing: border-box;
	display:flex;
	flex-direction: column;
	justify-content: space-between;
	width:100%;
	padding: $navbar-padding;
	background: #F0ECE7;

	.registration-rect{
		position: absolute;
		top:0;
		right:0;
	}
}

.builder-navbar .nav-content .builder-info-wrapper .profile-info{
	display:flex;
	flex-direction: column;

	.profile-info-wrapper{
		display: flex;
		align-items: center;

		.profile-icon{
			width: 75px;
			height: 75px;
			border-radius: 100%;
			object-fit: cover;
		}
	}
}

.builder-navbar .nav-content .builder-info-wrapper .profile-info .profile-info-wrapper .profile-name{
	display:flex;
	flex-direction: column;
	gap: .5rem;
	padding-top: .5rem;
	padding-left: 2rem;

	.current-user{
		font-size: 2.6rem;
		font-weight: 600;
	}

	.edit-info {
		font-family: Poppins, sans-serif;
		font-size: 1.4rem;
		font-weight: bold;
		color: $color-blue;

		&:hover{
			cursor: pointer;
		}
	}
}

.builder-navbar .nav-content .builder-info-wrapper .profile-info .swap-builders-wrapper{
	display: flex;
	flex-direction: column;
	margin-top:2rem;
	font-family: Poppins, sans-serif;

	.swap-builder-header{
		font-size: 1.4rem;
		font-weight:bold;
	}

	.builders{
		display:flex;
		flex-wrap: wrap;
		gap: 1.5rem;
		font-size: 1.4rem;
		font-weight: bold;
		color: $color-blue;

		&:hover{
			cursor: pointer;
		}

		.builder.unassigned{
			color: rgb(124, 124, 124);
			// cursor: not-allowed;
		}
	}
}

.builder-navbar .nav-content .progress-wrapper{
	box-sizing: border-box;
	display:flex;
	flex-direction: column;
	width:95%;
	padding:2rem;
	margin:auto;
	margin-top:1rem;
	margin-bottom: 1rem;
	background: #DEE2EE;
	border-radius: 2rem;

	.progress-header{
		overflow: hidden;
		font-family: Poppins, sans-serif;
		font-size: 1.6rem;
		font-weight: bold;
		color: $color-blue;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.progress{
		box-sizing: border-box;
		width: 100%;
		padding: 2rem;
		accent-color: $color-red;
	}

	.progress-bottom-text{
		box-sizing: border-box;
		display:flex;
		align-items: center;
		justify-content: space-between;
		line-height: 50%;
	}

	.percent, .next-task-due-wrapper{
		font-size: 1.3rem;
		color: #7189AD;
		cursor: pointer;
	}
}


.builder-navbar .nav-content .checklist-wrapper{
	flex-grow:1;
	overflow-x: hidden;
	overflow-y:  auto;

}

.builder-navbar .nav-content .nav-button{
	display:flex;
	flex-basis: 1;
	gap:1rem;
	align-items: center;
	height:5rem;
	padding:1rem;

	.icon{
		&:hover{
			cursor: pointer;
		}
	}

	.text{
		font-size: 1.8rem;

		&:hover{
			cursor: pointer;
		}
	}
}

.builder-navbar .nav-content .logout-wrapper .icon svg path{
	fill: black;
}
@media only screen and (width < 500px) {
	.builder-navbar .nav-content{
		height: 100%;
	}

	.builder-navbar .nav-content .progress-wrapper {
		padding-top:5px;
	}
}



