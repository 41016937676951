.center {
    display: flex;
    align-items: center;
}

.flex-center {
    display: flex;              // F
    flex-direction: column;     // C
    align-items: center;        // C
    justify-content: center;    // C
}

.flex-end{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.flex-left{
    display:flex;
    flex-direction: column;
    align-items: left;
}

.flex{
    display:flex;
    flex-direction: column;
    align-items: center;
}

.f-dir-row {
    flex-direction: row;
}

.f-justify-between {
    justify-content: space-between;
}

.f-gap-1 {
    gap: 1rem;
}


.g-option {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 500;
    padding: 1.2rem;
    box-sizing: border-box;
    background-color: #E1DFD4; //needs to be sand once we have the stylesheets
}

.temp-link {
    font-size: 2rem;
    color: #C7D9C8;
}

.g-lottery {
    content: "Our lottery is an algorithm that takes everyone that is eligible (based on age) and assigns you to a trip based on the trip preferences you submit. It will try to place you on your #1 preference, but if it's full, the system will continue down the preferences list until it finds an open spot. If none of your preference options are available, you'll be put on the waitlist. You can update your preferences each day until you're placed on a trip!";
}

.g-builder-header{
    font-family: 'Poppins', sans-serif;
    font-size: 3.2rem;
    font-weight: bold;
}

.g-builder-subheader{
    font-family: 'vista-slab', 'Minion Variable Concept', sans-serif;
    font-size: 1.6rem;
    font-weight: 300;
}



.g-page-style{
    position: relative;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	height: 100%;
	padding: 6rem 6rem 0;
	overflow-x: hidden;
}

@media (width < 500px) {
    .g-page-style {
        padding: 6rem 0;
    }
}

.g-page-style.beta-scrim{
    pointer-events: none;
}

.beta-scrim::after{
    content: "You will be able to start doing checklist tasks in a few days";
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;

    margin-top: 25%;
    margin-left: 25%;
    padding: 2rem;
    background: $color-coral;
    color: white;
    z-index: 6;
}
.beta-scrim::before{
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    z-index: 5;

    background: lightgrey;
}

.g-page-wrapper{
    box-sizing: border-box;
    flex-grow: 1;
	max-width: 1300px;
    overflow-x:hidden;
	overflow-y:auto;
	background:rgba(255, 255, 255, 0.80);
}

.g-page-container{
    box-sizing: border-box;
    height: 100%;
    padding:2rem 4rem;
//    background:orange !important;
}

.g-bottom-buttons{
	box-sizing: border-box;
    display:flex;
	flex-basis: 1;
    align-items: center;
	justify-content: space-between;
	max-width: 1300px;
	height: 9rem;
	padding: 2rem 4rem;
	background:rgba(255, 255, 255, 0.80);
	box-shadow:  0px -15px 10px -10px rgba(0,0,0,0.45);

    .g-back{
		font-family: 'vista-slab', 'Minion Variable Concept', sans-serif;
		font-size: 2.2rem;
		font-weight: 600;
		color: #8a94ad;
		background:none;
		border:none;
	}
}

.g-bottom-container-shadow{
    box-shadow:  0px -15px 10px -10px rgba(0,0,0,0.45);
}

.g-completed-wrapper{
    display:flex;
    gap:1rem;

    .g-completed{
        font-size: 2rem;
    }
}

@media only screen and (max-width: 500px) {
    .g-builder-header{
        margin-top: 1rem;
        font-size: 2.5rem;
    }

    .g-page-style{
        padding: unset;
    }

    .g-details{
        margin-top: 1rem;
    }
    
	.g-bottom-buttons{
        position: fixed;
        bottom: 0;
        width: 100%;
        height: 8rem;
        padding: 0.5rem 2rem;
    }

    .g-page-container{
        //padding: 3rem 2rem 0;
        padding: 4rem 1.5rem 0;
    }

    .g-page-wrapper:not(.g-no-bottom){
        margin-bottom: 8rem;
    }
}