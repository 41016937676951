/* stylelint-disable font-family-name-quotes */

.signup-loader{
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	background: #0000001a;
	border-radius: 2rem;

	span{
		font-size: 5rem;
	}
}

.signup-view{
	width:100%;
	height: 100%;
	border-radius: 2rem;
}

.signup-view #signup{
	position: relative;
	display: flex;
	flex-direction: column;
	gap: 2rem;
	align-items: center;
	width: calc(100% - 2.5rem);
	height: calc(80% - 2rem);
	padding: 1.25rem;

	.title{
		width: 75%;
		margin-top: 7%;
	}

	.input-container{
		display: flex;
		flex-direction: column;
		gap: 2rem;
		align-content: center;
		width: 75%;

		.email,
		.password{
			width: calc(100% - 4rem);
			height: calc(60px - 2rem);
			padding: 1rem 2rem;
			font-size: 2.4rem;
			font-weight: 300;
			background: $color-input-grey;
			border: unset;
			border-radius: 8px;
		}

		.forgot-password .forgot{
			font-family: $font-poppins;
			background-color: unset;
			border: unset;
		}
	}

	.signup-btn-container{
		.signin{
			width: 180px;
			padding: 1.25rem;
			color: $color-white;
			background: $color-red;
			border: unset;
			border-radius: 20px;
		}

		.signin:disabled{
			cursor: not-allowed;
			background:#aaa;
		}
	}
}

.signup-view .login-container{
	display: flex;
	align-items: center;
	justify-content: space-around;
	width:100%;
	height: 20%;
	background: $color-red;
	border-radius: 0 0 20px 20px;
}

.signup-view .login-container .right .signin{
	width: 180px;
	padding: 1.25rem;
	color: $color-red;
	background: $color-white;
	border: unset;
	border-radius: 20px;
}

.signup-view .login-container .left{
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 1rem;
	font-family: $font-poppins;

	.heading{
		font-size: clamp(34px, 38px, 42px);
		color: $color-white;
	}

	.sub-headings{
		display: flex;
		flex-direction: column;
		text-align: center;

		.sub-heading{
			font-size: clamp(20px, 24px, 28px);
			line-height: 1;
			color: $color-white;

		}
	}
}

.sign-leave-to,
.sign-enter-from,
.input-leave-to,
.input-enter-from{
	opacity: 0;
}

.sign-enter-active,
.sign-leave-active,
.input-enter-active,
.input-leave-active{
	transition: all .5s ease;
}

.sign-leave-to{
	transform: translateY(100%);
}
.sign-enter-from{
	transform: translateY(-100%);
}
.input-leave-to{
	transform: translateY(25%);
}
.input-enter-from{
	transform: translateY(-25%);
}

//$mobile-width
@media only screen and (max-width: 500px) {
	.signup-view{
		width: 100%;
		height: 100%;
	}

	.signup-view #signup{
		height: 70%;

		.close{
			position: absolute;
			top: 1rem;
			right: 1rem;
		}
		.title{
			width:95%;
			font-size: 30px;

		}
		.input-container{
			width:95%;

			.email, .password{
				font-size: 1.8rem;
			}
			.password-reqs{
				margin-left:2.25rem;
			}
		}
	}

	.signup-view #signup .signup-btn-container{
		position: absolute;
		bottom: 10%;

		.signin{
			padding: 1rem;
			font-size: 2rem;
		}
	}

	.signup-view .login-container{
		height: calc(30% - 2.5rem);
		display: flex;
		flex-direction:column;
		justify-content: space-around;
		padding:0;
		margin:0;
		border-radius: 0px;

		.left{
			padding:0;
			margin:0;

			.heading{
				font-size: 3rem;
			}

			.sub-headings{
				.sub-heading{
					font-size: 2rem;
				}
			}

		}

		.right{
			padding:0;
			margin:0;

			.signin{
				padding: 1rem;
				font-size: 2rem;
			}
		}
	}
}

//	button.signin{
//		border: unset;
//		width: 200px;
//		background: #C7D9C8;
//		color: #1E3D3E;
//		border: 1px solid #1E3D3E;
//		padding: 1.5rem;
//		border-radius: 20px;

//		&:hover{
//			cursor: pointer;.s
//		}
//		&:disabled{
//			background:#aaaaaa;
//			cursor: not-allowed;
//		}
//	}
//}

//@media screen and (max-width: 1600px) {
//	.login-container{
//		.left{
//			.header{
//				font-size: 35px;
//			}
//			.sub-heading{
//				font-size: 30px;
//			}
//		}
//	}
//}

//@media screen and (max-width: 1250px) {
//	.login-container{
//		.left{
//			.header{
//				font-size: 30px;
//			}
//			.sub-heading{
//				font-size: 25px;
//			}
//		}
//	}
//}