



.whole-page .page-container{
    overflow-x: hidden;
	overflow-y: auto;
	background: #fffc;
}

.whole-page .content-wrapper{
	// height: calc(100% - 24rem - 9rem);
	height: fit-content;
	max-height: 100%;
    padding: 2rem 4rem;

	// border: 1px solid pink;
	// box-shadow: inset 0 -15px 10px -10px #00000073;
}
