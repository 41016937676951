.login-component {
  margin: auto;
}
.error-message {
  padding: 5px;
  background: #ff4d4d;
  border-radius: 8px;
}.signup-component-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: space-around;
  position: relative;
}
.signup-component-container .signup-input-container {
  width: 100%;
  display: flex;
  align-items: center;
}
.signup-component-container .signup-input-container i {
  font-size: 22px;
  margin-top: 20px;
  font-weight: 600;
  color: greenyellow;
}
.signup-component-container .fieldset {
  width: 100%;
  display: flex;
  align-items: center;
}
.signup-component-container .fieldset .Password {
  margin: 0;
}.auth-component button {
  width: 100%;
  margin-bottom: 1em;
}
.auth-component .auth-menu {
  margin-top: 2em;
  list-style: none;
}
.auth-component input {
  width: 100%;
  margin-bottom: 1em;
}
.auth-component h2 {
  margin-bottom: 1em;
  text-align: center;
}/* stylelint-disable-next-line at-rule-no-unknown */
.fire-input {
  position: relative;
  box-sizing: border-box;
  display: inline-block;
}
.fire-input input, .fire-input select, .fire-input .value {
  box-sizing: border-box;
  display: inline-block;
  width: 100%;
  height: 30px;
}
.fire-input input:focus, .fire-input select:focus {
  outline: unset;
}
.fire-input .status {
  position: absolute;
  top: 50%;
  right: 0;
  z-index: 10;
  display: inline-block;
  padding: 4px 5px;
  font-size: 8px;
  pointer-events: none;
  background: rgba(255, 255, 255, 0.6);
  border: solid 1px #f0f0f0;
  border-radius: 100%;
  transform: translateY(-50%);
}
.fire-input .status.save-success {
  color: white;
  background: green;
}
.fire-input.has-prefix input, .fire-input.has-prefix select, .fire-input.has-prefix .value {
  padding-left: 15px;
}
.fire-input.has-prefix .prefix {
  position: absolute;
  top: 50%;
  left: 5px;
  color: gray;
  transform: translateY(-50%);
}
.fire-input.invalid input {
  color: red;
  border-color: red;
}
.fire-input .fade-enter-active, .fire-input .fade-leave-active {
  transition: opacity 0.25s;
}
.fire-input .fade-enter, .fire-input .fade-leave-to {
  opacity: 0;
}.scrim .modal header {
  display: flex;
  flex-direction: row;
}
.scrim .modal header h3 {
  flex-grow: 3;
}
.scrim .modal header .close {
  width: 20px;
}.dropdown-wrapper {
  position: relative;
}
.dropdown-wrapper .dropdown {
  position: absolute;
  background: white;
  border: lightgray 1px solid;
  box-shadow: 0 0 5px 0 #DBDBDB;
}
.dropdown-wrapper .dropdown li {
  padding: 10px;
}
.dropdown-wrapper .dropdown li:not(:last-child) {
  border-bottom: #c8c8c8 solid 1px;
}
.dropdown-wrapper.expand-up .dropdown {
  bottom: 0;
}.tag-creator {
  position: relative;
}
.tag-creator .controls {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: row;
}
.tag-creator .controls .icon {
  padding: 10px;
}.tag-list-editor .tag-list {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.tag-list-editor .add-tag .icon {
  margin-right: 9px;
}
.tag-list-editor .tag {
  display: flex;
  align-items: center;
}
.tag-list-editor .tag:not(:first-child) {
  margin-left: 10px;
}.snackbar-container {
  position: absolute;
  top: 40px;
  height: 120px;
  width: 460px;
  right: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #323232;
  border-radius: 8px;
  animation: 0.5s slideInLeft cubic-bezier(0.075, 0.82, 0.165, 1);
}
@keyframes slideInLeft {
0% {
    transform: translateX(100%);
}
55% {
    transform: translateX(100%);
}
100% {
    transform: translateX(0%);
}
}
.snackbar-container .snackbar-message {
  font-size: 14px;
  color: white;
}.v-checkbox-container .checkbox:not(.checked) {
  width: 20px;
  height: 20px;
  background: lightgrey;
  border-radius: 5px;
}
.v-checkbox-container .checkbox:not(.checked).disabled {
  background: gray;
}
.v-checkbox-container .checkbox.checked {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  background: #FFA502;
  border-radius: 5px;
}
.v-checkbox-container .checkbox.checked .icon {
  color: white;
}
.v-checkbox-container .checkbox.checked.disabled {
  background: gray;
}.radioset {
  width: 100%;
  height: 100%;
}
.radioset .radioset-label {
  font-weight: bold;
  margin-bottom: 0.5rem;
}
.radioset .radioset-options.row {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}
.radioset .radioset-options .radioset-option {
  display: flex;
  margin: 0.25em;
  align-items: center;
}
.radioset .radioset-options .radioset-option .label {
  padding-left: 0.5rem;
}.twoPointSlider {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
  background: white;
}
.twoPointSlider .slider-container {
  position: relative;
  width: 100%;
  height: 90px;
}
.twoPointSlider .slider-container .start,
.twoPointSlider .slider-container .end {
  display: unset;
  position: absolute;
  height: 1.7rem;
  width: 1.7rem;
  background-color: #dadae5;
  pointer-events: auto;
  border-radius: 50%;
  top: 34%;
}
.twoPointSlider .slider-container .start {
  left: 0;
}
.twoPointSlider .slider-container .end {
  right: 0;
}
.twoPointSlider .slider-container .slider {
  z-index: 1;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  outline: none;
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 6px;
  background-color: transparent;
  pointer-events: none;
}
.twoPointSlider .slider-container .slider-track {
  width: 100%;
  height: 8px;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  border-radius: 5px;
}
.twoPointSlider .slider-container .slider::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  height: 5px;
}
.twoPointSlider .slider-container .slider::-moz-range-track {
  -moz-appearance: none;
  height: 5px;
}
.twoPointSlider .slider-container .slider::-ms-track {
  appearance: none;
  height: 5px;
}
.twoPointSlider .slider-container .slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 1.7rem;
  width: 1.7rem;
  background-color: #DA3A34;
  cursor: pointer;
  margin-top: -9px;
  pointer-events: auto;
  border-radius: 50%;
}
.twoPointSlider .slider-container .slider::-moz-range-thumb {
  -webkit-appearance: none;
  height: 1.7rem;
  width: 1.7rem;
  cursor: pointer;
  border-radius: 50%;
  background-color: #DA3A34;
  pointer-events: auto;
}
.twoPointSlider .slider-container .slider::-ms-thumb {
  appearance: none;
  height: 1.7rem;
  width: 1.7rem;
  cursor: pointer;
  border-radius: 50%;
  background-color: #DA3A34;
  pointer-events: auto;
}
.twoPointSlider .slider-container .slider:active::-webkit-slider-thumb {
  background-color: #ffffff;
  border: 3px solid #DA3A34;
}
.twoPointSlider .values {
  display: flex;
  width: 100%;
  justify-content: space-around;
  font-size: 1.6rem;
  position: absolute;
  margin: auto;
  top: 0;
  color: #DA3A34;
}
.twoPointSlider .values::before {
  content: "";
  position: absolute;
  height: 0;
  width: 0;
  margin: auto;
}.custom-input {
  position: relative;
  margin: 2rem 0 1rem;
}
.custom-input .input {
  padding: 10px;
  width: 100%;
  border: none;
  border-radius: 5px;
  font: inherit;
  color: #000;
  background-color: transparent;
  outline: 2px solid #000;
}
.custom-input .input-label {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(10px, 10px);
  transition: transform 0.25s;
}
.custom-input .input:focus + .input-label,
.custom-input .input:valid + .input-label {
  transform: translate(10px, -1em) scale(0.8);
  color: #fd2b32;
  padding-inline: 5px;
  background-color: #fff;
  font-size: 1.5rem;
}
.custom-input .input:is(:focus, :valid) {
  outline-color: #fd2b32;
}
.custom-input .input:disabled {
  opacity: 0.5;
}
.custom-input .input:disabled + .input-label {
  opacity: 0.7;
}.popup {
  position: fixed;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 10;
}
.embeded {
  width: 400px;
  height: 600px;
}
.date-picker-container {
  border-radius: 5px;
}
.date-picker-container .date-picker {
  z-index: 11;
  position: relative;
  width: 400px;
  height: 600px;
}
.date-picker-container .top-container {
  height: 110px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.date-picker-container .top-container .title {
  font-size: 16px;
  position: absolute;
  top: 10%;
  left: 5%;
}
.date-picker-container .top-container .selected-date {
  margin-left: 4%;
  font-size: 42px;
}
.date-picker-container .bottom-container {
  height: 85%;
  font-size: 16px;
  text-align: center;
}
.date-picker-container .bottom-container .month-select {
  margin: auto;
  display: flex;
  width: 45%;
  padding-top: 5%;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  font-weight: bold;
}
.date-picker-container .bottom-container .month-select .right-chevy, .date-picker-container .bottom-container .month-select .left-chevy {
  font-size: 20px;
}
.date-picker-container .bottom-container .month-select .right-chevy:hover, .date-picker-container .bottom-container .month-select .left-chevy:hover {
  cursor: pointer;
}
.date-picker-container .bottom-container .dates-container {
  display: grid;
  height: 80%;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  margin: auto;
  margin-top: 5%;
  width: 90%;
}
.date-picker-container .bottom-container .dates-container .dayOfWeek {
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.date-picker-container .bottom-container .dates-container .days {
  grid-column: var(--column);
  height: 75%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.date-picker-container .bottom-container .dates-container .days:hover {
  cursor: pointer;
}
.date-picker-container .bottom-container .dates-container .selected {
  border-radius: 50px;
}
.date-picker-container .bottom-container .dates-container .disabled {
  font-weight: bolder;
}#version-update-banner {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #6970ff;
  color: white;
  z-index: 1000;
  padding: 15px;
  font-size: 20px;
  text-align: center;
  -webkit-box-shadow: 0px 10px 23px -4px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 10px 23px -4px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 10px 23px -4px rgba(0, 0, 0, 0.75);
  z-index: 10000;
}
#version-update-banner button {
  appearance: none;
  border-radius: 20px;
  padding: 5px 20px;
  margin-left: 2em;
  font-weight: bold;
  font-size: 18px;
}
#version-update-banner button.primary {
  border: solid 2px white;
  background: rgba(0, 0, 0, 0.3);
  color: white;
}
#version-update-banner button.later {
  border: none;
  background: none;
  color: white;
}#version-update-fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 10000;
}
#version-update-fullscreen aside {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 500px;
  max-width: 90vw;
  max-height: 90vh;
  background: #6970ff;
  color: white;
  z-index: 1000;
  padding: 80px 25px;
  font-size: 20px;
  text-align: center;
  -webkit-box-shadow: 0px 10px 23px -4px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 10px 23px -4px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 10px 23px -4px rgba(0, 0, 0, 0.75);
}
#version-update-fullscreen aside button {
  appearance: none;
  border-radius: 20px;
  padding: 8px 25px;
  font-weight: bold;
  font-size: 18px;
}
#version-update-fullscreen aside button.primary {
  border: solid 2px white;
  background: rgba(0, 0, 0, 0.3);
  color: white;
}
#version-update-fullscreen aside button.later {
  border: none;
  background: none;
  color: white;
}#app-lockdown-gate {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.9);
  z-index: 10000;
}
#app-lockdown-gate aside {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fff;
  font-size: 20px;
  z-index: 1000;
}section.announcement {
  position: relative;
}
section.announcement .announcement-card {
  padding: 1rem;
  background: white;
}
section.announcement .announcement-date {
  margin-bottom: 2rem;
  color: white;
}
section.announcement.unread::before {
  position: absolute;
  top: 5rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background-color: cyan;
  border-radius: 50%;
}
section.announcement.urgent .announcement-card {
  border-left: 0.5rem solid #975E1B;
}
section.announcement.urgent .announcement-date {
  color: #975E1B;
}aside.announcement-container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;
  display: grid;
  place-items: end center;
  width: 100%;
  height: 100%;
  font-family: Inter, sans-serif;
  font-size: 14px;
  pointer-events: none;
}
aside.announcement-container > * {
  pointer-events: all;
}
aside.announcement-container .an-scrim-fadein-enter-active,
aside.announcement-container .an-scrim-fadein-leave-active {
  transition: opacity 0.5s ease;
}
aside.announcement-container .an-scrim-fadein-enter-from,
aside.announcement-container .an-scrim-fadein-leave-to {
  opacity: 0;
}
aside.announcement-container .announcement-scrim {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9333333333);
  backdrop-filter: blur(4px);
}
aside.announcement-container .announcement-preview-enter-active,
aside.announcement-container .announcement-preview-leave-active,
aside.announcement-container .announcement-shift-enter-active,
aside.announcement-container .announcement-shift-leave-active {
  transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
aside.announcement-container .announcement-preview-leave-active,
aside.announcement-container .announcement-shift-leave-active {
  position: absolute;
}
aside.announcement-container .announcement-preview-enter-active {
  transition-delay: 0.5s;
}
aside.announcement-container .announcement-preview-enter-from,
aside.announcement-container .announcement-preview-leave-to,
aside.announcement-container .announcement-shift-enter-from,
aside.announcement-container .announcement-shift-leave-to {
  opacity: 0;
  transform: translateY(100%);
}
aside.announcement-container .announcement-preview-container {
  width: 40rem;
  background-color: #393939;
  border: 2px solid #393939;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.9333333333);
}
aside.announcement-container .announcement-preview-container.urgent {
  padding-bottom: 1rem;
  font-size: large;
  background: unset;
  border: unset;
  box-shadow: unset;
}
@media screen and (width < 400px) {
aside.announcement-container .announcement-preview-container {
    width: calc(100% - 2rem);
}
}
aside.announcement-container header {
  display: flex;
  align-items: center;
  padding: 0.1rem 0.1rem 0.1rem 1rem;
  color: white;
}
aside.announcement-container header .text {
  margin-right: auto;
  font-weight: bold;
}
aside.announcement-container header .more {
  padding: 0.25rem 0.5rem;
  white-space: nowrap;
  border: 1px solid white;
  border-radius: 2rem;
  /* stylelint-disable-next-line max-nesting-depth */
}
@media screen and (width < 240px) {
aside.announcement-container header .more {
    display: none;
}
}
aside.announcement-container header button {
  font-size: 1.5em;
  color: inherit;
  background: unset;
  border: unset;
  outline: unset;
}
aside.announcement-container .announcement-list {
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  width: 48rem;
  max-width: 100%;
  max-height: 100%;
  padding: 0 2rem 4rem;
  overflow-y: auto;
}
aside.announcement-container .announcement-list header {
  position: sticky;
  top: 0;
  z-index: 80;
  padding: 1.5rem;
  overflow: clip visible;
  font-size: x-large;
  background-color: rgba(0, 0, 0, 0.9333333333);
}
aside.announcement-container .announcement-list header .text {
  text-overflow: clip;
  white-space: nowrap;
}
@media screen and (width < 480px) {
aside.announcement-container .announcement-list {
    width: 100%;
}
}
aside.announcement-container .announcement-list::-webkit-scrollbar {
  background: unset;
}
aside.announcement-container .announcement-list::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.2666666667);
  border-radius: 2rem;
}
aside.announcement-container .announcement-empty {
  margin: 0.1rem;
  color: #AAA;
  background: white;
  border: 2px solid #AAA;
}
aside.announcement-container .announcement-empty header {
  gap: 2rem;
  padding: 1rem 0.5rem;
  color: inherit;
}
aside.announcement-container .announcement-empty header .more {
  border: 1px solid #AAA;
}