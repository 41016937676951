/* stylelint-disable color-hex-length */
/* stylelint-disable scss/dollar-variable-empty-line-before */
/* stylelint-disable scss/dollar-variable-colon-space-after */

/**
*  COLORS FOR HXP
*/

$color-coral:           #CE5143FF; // hsl(006, 059%, 054%);
$color-mint:            #C7D9C8FF; // hsl(123, 019%, 082%);
$color-off-mint:        #8FC1BBFF; // hsl(173, 029%, 066%);
$color-dark-green:      #1E3D3EFF; // hsl(182, 035%, 018%);
$color-light-tan:       #F7F6F1FF; // hsl(050, 027%, 096%);
$color-sand:            #E1DFD4FF; // hsl(051, 018%, 086%);
$color-tan:             #C4B197FF; // hsl(035, 028%, 068%);
$color-white:           #FEFFFDFF; // hsl(090, 100%, 100%);
$color-orange-cream:    #FFC58FFF; // hsl(029, 100%, 078%);

$color-red:             #CE5142FF;
$color-blue:            #5972B7FF;
$color-blue-grey:       #8A94ADFF;
$text-button-mint:      #33B7A7FF;
$color-grey-b1:         #B1B1B1FF;

$color-link:            #57C3FAFF;

$color-tuscan-red:      #8A4D4DFF; /* hsl( 000, 044, 054 ) brick red    */
$color-dark-coral:      #CE5142FF; /* hsl( 006, 068, 081 ) = HXP Red    */
$color-error:           #D7612FFF;
$color-mac-n-cheese:    #FFC58FFF; /* hsl( 029, 044, 100 ) = Creamsicle */
$color-khaki:           #C4B197FF; /* hsl( 035, 028, 068 ) = Tan        */
$color-bronze:          #A59E5EFF; /* hsl( 054, 043, 065 ) = Goldenrod  */
$color-raw-umber:       #797143FF; /* hsl( 051, 045, 047 ) New Tan      */
$color-mint:            #C7D9C8FF; /* hsl( 123, 008, 085 ) = Mint       */
$color-drk-sea-green:   #99BA9AFF; /* hsl( 122, 018, 073 ) mint shadow  */
$color-deep-moss-green: #3A583BFF; /* hsl( 124, 034, 035 ) Green        */
$color-cambridge-blue:  #8FC1BBFF; /* hsl( 173, 026, 076 ) Off Mint     */
$color-midnight-green:  #1E3D3EFF; /* hsl( 189, 081, 028 ) green dark   */
$color-cool-grey:       #8A94ADFF; /* hsl( 223, 020, 068 ) grey blue    */
$color-denim:           #5972B7FF; /* hsl( 224, 051, 072 ) = Blue       */

$color-nearly-white:    #FEFFFDFF; /* hsl( 090, 001, 100 ) nearly white */
$color-cultred:         #F7F6F1FF; /* hsl( 050, 002, 097 ) Cloud        */
$color-input-grey:      #F4F4F4FF; /* hsl( 000, 000, 096 ) Input Grey   */
$color-platinum:        #E4E4E4FF; /* hsl( 359, 000, 089 ) Outline      */
$color-rich-black:      #000013FF; /* hsl( 000, 000, 000 ) nearly black */
$color-black:           #000000FF;
$color-card-shadow:     #0000000D;
$color-shadow-soft:     #00000024;
$color-shadow-popup:    #00000044;

$color-primary:   $color-dark-coral;
$color-secondary:      $color-denim;
$color-tertiary:       $color-khaki;


$mobile-width:          500px;
$shadow-soft:  0 6px 18px 0 $color-shadow-soft;

$border-1-b1: 1px solid $color-grey-b1;

$box-shadow-0-4-6-0: 0 4px 6px 0 $color-shadow-popup;

// Added Nov 15 and later

// $color-gray-a3: #A3A3A3FF; use gray-b1 instead

$navbar-padding: 3rem;

.builder-selection{
    position: relative;
    display:flex;
    height:calc(100% - 2rem);
    padding-top:3rem;

    .icon-flex{
        display: inline-flex;
        gap: 1rem;
        align-items: center;
    }
    
    .no-builder{
        position: relative;
        z-index:3;
        width:90%;
        // height: 100%;
        margin: 0 auto;
        overflow-y:auto;

        .add-builder, .add-prev-builder {
            z-index:4242;
            margin-top: 1.5rem;
            cursor: pointer;
    
            .add-icon{
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .add-text{
                font-size: 16px;
                font-weight: 300;
                color: black;
            }
        }

        .add-builder-warning {
            z-index:5;
            margin-top:2rem;

            .start-here{
                width:100%;
            }
        }

        .what-is-a-builder{
            width: 85%;
        }
    }

    .selected-group{
        position: relative;
        width: 90%;
        height: 100%;
        margin:auto;
        overflow-y:auto;

        .builder-card{
            display: flex;
            gap: 1rem;
            align-items: center;
            width: 100%;
            cursor: pointer;

            .profile-card{
                width: 90%;

                .text{
                    font-size: 1.5rem;
                    
                }
                
                .card{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: calc(100% - 2rem);
                    padding: 1rem;
                    font-size: 2rem;
                    color: white;
                    background: $color-tan;
                }

                .chevy{
                    display: flex;
                }
            }
        }

        .profile-select{
            position: fixed;
            z-index: 5;
            display: flex;
            flex-direction: column;
            min-width: 200px;
            margin-top: 1rem;
            background: white;
            box-shadow: 1px 2px 5px rgba($color-card-shadow, 0.25);

            .groups-container{
                max-height: clamp(50px, 50vh, 100vh - 400px);
                overflow-y: auto;
            }

            .groups{
                padding: 0.75rem 0.5rem;
                // margin-bottom: 5px;
                cursor: pointer;
                
                &:hover:not(.edit-icon){
                    color: white;
                    background-color: $color-blue;

                    svg path{
                        fill: white
                    }
                }

                .edit-icon{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 2rem;
                    height: 2rem;
                    padding: 0.5rem;

                    svg path{
                        fill: $color-blue-grey;
                    }

                    &:hover{
                        background: white;
                        border-radius: 100%;
                        svg path{
                            fill: $color-blue-grey;
                        }
                    }
                }

                .group-member{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 0.5rem;
                    font-size: 2rem;
                    
                }
            }

            .add-builder{
                padding: 1rem;
                cursor: pointer;
                border-top: 1px solid #E3E3E3;

                .add-text{
                    color: $color-blue;
                }

                &:hover{
                    color: white;
                    background-color: $color-blue;

                    .add-icon svg path{
                        fill: white;
                    }

                    .add-text{
                        color: $color-white;
                    }
                }
            }
        }

        .popup-section{
            position: relative;
            width: 100%;
            margin-top: 4rem;
            z-index:4;
            svg{
                width:100%;
            }
        }

        .popups{
            width: 92%;
        }
    }

}

@media only screen and (max-width: 500px) {
    .builder-selection{
        height: calc(100% - 10rem);
        padding:1.5rem;
        padding:0;
        overflow-y:auto;
    }
    
    .builder-selection .no-builder{
        
        .add-builder{
            margin-top:4rem;
        }
        .add-builder-warning{
            margin-top:2rem;

            .start-here{
                width:100%;
            }
        }

        .what-is-a-builder{
            width: 90% ;
            margin-top: 2rem;
        }
    }

    .builder-selection .selected-group{
        z-index:5;
        height: 99%;
        padding:0;
    }

    .profile-select .groups-container{
        max-height: clamp(50px, 50vh, 100vh - 300px);
        overflow-y: auto;
    }

   .builder-selection .selected-group .popups {
        position: relative;
        width: 85%;
        margin: 0;
   }

   .builder-selection .selected-group .popup-section{
       position:relative;
        display:flex;
        flex-basis: 0;
        flex-direction: column;
        flex-grow:1;
        margin-top:1rem;

        svg{
            width:95%;
        }
   }

   .builder-selection .selected-group .builder-card .profile-card .card{
        width: calc(100% - 2rem);
   }

   .builder-selection .selected-group .builder-card{
        margin-top:2rem;
        margin-bottom:4rem;
   }
}

