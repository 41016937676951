@import url("https://use.typekit.net/kos4yoh.css");
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Permanent+Marker&family=Roboto:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&family=Rajdhani:wght@300;400;500;600;700&display=swap');

$font-family-base: "vista-slab", serif;


/**
 * Font Weights
 */
$font-weight-thin         : 100;
$font-weight-extra-light  : 200;
$font-weight-light        : 300;
$font-weight-regular      : 400;
$font-weight-medium       : 500;
$font-weight-semi-bold    : 600;
$font-weight-bold         : 700;
$font-weight-extra-bold   : 800;
$font-weight-black        : 900;
 
/**
 * Text default styles
 */

 //Minion Variable not imported. 
// .g-headline {
//     font-family: 'Minion Variable Concept', sans-serif;
// }

// .g-subheading {
//     font-family: 'vista-slab','Minion Variable Concept', sans-serif;
//     font-style: normal;
//     font-weight: 500;
// }

// .g-bodycopy {
//     font-family: 'vista-slab', 'Minion Variable Concept', sans-serif;
//     font-style: normal;
//     font-weight: 300;
// }
// //Black seashore not imported. 
// .quotes {
//     font-family: 'Black Seashore', 'Minion Variable Concept', sans-serif;
// }

/// Values from high fidelity designs
.g-heading-large{
    font-size: 64px;
    font-family: 'Minion Variable Concept', sans-serif;
}
.g-heading-medium{
    font-size: 48px;
    font-family: 'Minion Variable Concept', sans-serif;
    font-weight: $font-weight-regular;
}
.g-heading-small{
    font-size: 40px;
    font-family: 'Minion Variable Concept', sans-serif;
    font-weight: $font-weight-regular;
}
.g-minion-header{
    font-size: 24px;
    font-family: 'Minion Variable Concept', sans-serif;
    font-weight: $font-weight-regular;
}
.g-vista-header{
    font-size: 24px;
    font-family: 'vista-slab ', 'Minion Variable Concept', sans-serif;
    font-weight: $font-weight-regular;
}

.g-subheading{
    font-size: 32px;
    font-family: 'vista-slab ', 'Minion Variable Concept', sans-serif;
    font-weight: $font-weight-regular;
}
.g-body-text{
    font-size: 20px;
    // font-size: 24px;
    //font-family: 'vista-slab', 'Minion Variable Concept', sans-serif;
    font-family: 'Poppins', sans-serif;
    font-weight: $font-weight-light;
}
.g-body-text-small{
    font-size: 16px;
    // font-size: 20px;
    //font-family: 'vista-slab', 'Minion Variable Concept', sans-serif;
    font-family: 'Poppins', sans-serif;
    font-weight: $font-weight-light;
}
.g-body-text-tiny{
    font-size: 14px;
    //font-family: 'vista-slab', 'Minion Variable Concept', sans-serif;
    font-family: 'Poppins', sans-serif;
    font-weight: $font-weight-light;
}
.g-btn-text{
    font-size: 24px;
    font-family: 'vista-slab', 'Minion Variable Concept', sans-serif;
    font-weight: $font-weight-light;
}
.g-btn-text-small{
    font-size: 20px;
    font-family: 'vista-slab', 'Minion Variable Concept', sans-serif;
    font-weight: $font-weight-light;
}
.g-btn-text-xsmall {
    font-size: 11px;
    font-family: 'vista-slab', 'Minion Variable Concept', sans-serif;
    font-weight: $font-weight-regular;
}

.medium{
    font-weight: 500;
}

.g-link {
    font-size: 2rem;
    color: black;
    display: flex;
    align-items: center;

    &:link,
    &:visited,
    &:hover,
    &:active {
        text-decoration: none;
    }
}
.g-small-subheading{
    font-size: 20px;
    font-weight: $font-weight-regular;
    font-family: 'Poppins', sans-serif;
}