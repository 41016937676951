// Links
a {
    color: $color-link;
    text-shadow: none;
    transition: text-shadow .15s ease;
    cursor: pointer;
}
a:hover {
    // ? Text shadow, yea or nay?
    text-shadow: 1px 1px rgba($color-card-shadow, 0.1);
}