/* stylelint-disable color-hex-length */
/* stylelint-disable scss/dollar-variable-empty-line-before */
/* stylelint-disable scss/dollar-variable-colon-space-after */

/**
*  COLORS FOR HXP
*/

$color-coral:           #CE5143FF; // hsl(006, 059%, 054%);
$color-mint:            #C7D9C8FF; // hsl(123, 019%, 082%);
$color-off-mint:        #8FC1BBFF; // hsl(173, 029%, 066%);
$color-dark-green:      #1E3D3EFF; // hsl(182, 035%, 018%);
$color-light-tan:       #F7F6F1FF; // hsl(050, 027%, 096%);
$color-sand:            #E1DFD4FF; // hsl(051, 018%, 086%);
$color-tan:             #C4B197FF; // hsl(035, 028%, 068%);
$color-white:           #FEFFFDFF; // hsl(090, 100%, 100%);
$color-orange-cream:    #FFC58FFF; // hsl(029, 100%, 078%);

$color-red:             #CE5142FF;
$color-blue:            #5972B7FF;
$color-blue-grey:       #8A94ADFF;
$text-button-mint:      #33B7A7FF;
$color-grey-b1:         #B1B1B1FF;

$color-link:            #57C3FAFF;

$color-tuscan-red:      #8A4D4DFF; /* hsl( 000, 044, 054 ) brick red    */
$color-dark-coral:      #CE5142FF; /* hsl( 006, 068, 081 ) = HXP Red    */
$color-error:           #D7612FFF;
$color-mac-n-cheese:    #FFC58FFF; /* hsl( 029, 044, 100 ) = Creamsicle */
$color-khaki:           #C4B197FF; /* hsl( 035, 028, 068 ) = Tan        */
$color-bronze:          #A59E5EFF; /* hsl( 054, 043, 065 ) = Goldenrod  */
$color-raw-umber:       #797143FF; /* hsl( 051, 045, 047 ) New Tan      */
$color-mint:            #C7D9C8FF; /* hsl( 123, 008, 085 ) = Mint       */
$color-drk-sea-green:   #99BA9AFF; /* hsl( 122, 018, 073 ) mint shadow  */
$color-deep-moss-green: #3A583BFF; /* hsl( 124, 034, 035 ) Green        */
$color-cambridge-blue:  #8FC1BBFF; /* hsl( 173, 026, 076 ) Off Mint     */
$color-midnight-green:  #1E3D3EFF; /* hsl( 189, 081, 028 ) green dark   */
$color-cool-grey:       #8A94ADFF; /* hsl( 223, 020, 068 ) grey blue    */
$color-denim:           #5972B7FF; /* hsl( 224, 051, 072 ) = Blue       */

$color-nearly-white:    #FEFFFDFF; /* hsl( 090, 001, 100 ) nearly white */
$color-cultred:         #F7F6F1FF; /* hsl( 050, 002, 097 ) Cloud        */
$color-input-grey:      #F4F4F4FF; /* hsl( 000, 000, 096 ) Input Grey   */
$color-platinum:        #E4E4E4FF; /* hsl( 359, 000, 089 ) Outline      */
$color-rich-black:      #000013FF; /* hsl( 000, 000, 000 ) nearly black */
$color-black:           #000000FF;
$color-card-shadow:     #0000000D;
$color-shadow-soft:     #00000024;
$color-shadow-popup:    #00000044;

$color-primary:   $color-dark-coral;
$color-secondary:      $color-denim;
$color-tertiary:       $color-khaki;


$mobile-width:          500px;
$shadow-soft:  0 6px 18px 0 $color-shadow-soft;

$border-1-b1: 1px solid $color-grey-b1;

$box-shadow-0-4-6-0: 0 4px 6px 0 $color-shadow-popup;

// Added Nov 15 and later

// $color-gray-a3: #A3A3A3FF; use gray-b1 instead


.builder-checklist{
	display:flex;
	flex-direction: column;
}

.builder-checklist .checklist-section-header
{
	position: relative;
	display:flex;
	gap: 1.5rem;
	align-items: center;
	padding: 1rem 1rem;
	padding-left: 10%;
	font-size: 1.8rem;
	color: #797143;
	background: #F2F0EB;
}
.builder-checklist .checklist-check{
	position: relative;
	display:flex;
	gap: 1.5rem;
	align-items: center;
	padding: .5rem 1rem;
	font-size: 1.8rem;

	.checklist-main-content{
		display: flex;
		gap: 1.5rem;
		align-items: center;
		width:90%;
		height:100%;
		padding: .5rem;
	}

	&.sub-check{
		width: calc(100% - 4rem);
		padding-left:4rem;
	}

	&:hover{
		cursor: pointer;
	}

	.checklist-index{
		display:flex;
		align-items: center;
		justify-content: center;
		width: 3rem;
		height: 3rem;
		border-radius: 5px;

		.checkbox{
			background:black;
			width: 1.5rem;
			height: 1.5rem;
			border-radius: 5px;
		}

		&.complete{
			width: 3rem;
			height: 3rem;
			background:none;
			border-radius: 0;

			svg path{fill: #4F8B53}
		}

		&.delayed{
			width: 3rem;
			height: 3rem;
			background:none;
			border-radius: 0;

			svg path{fill: #C9A45E}
		}

		&.overdue{
			.checkbox{
				background:#CE5142;
			}
		}
	}

	.chevy{
		height:100%;
		transform: rotateX(0deg);
	}

	.rotated{
		transform: rotateX(180deg);
	}

	.checklist-content{
		box-sizing: border-box;
		color: black;

		.additional-info{
			&.overdue{
				font-weight: bold;
				color: #CE5142;
			}

			&.delayed{
				color: #C9A45E;
			}
		}
	}
}

.builder-checklist .checklist-check.selected{
	background: $color-blue;

	.checklist-index{

		&.complete{
			background:none;

			svg path{fill: white}
		}

		&.delayed{
			background:none;

			svg path{fill: white}
		}

		.checkbox{
			background: white;
		}
	}

	.chevy{
		svg path{
			fill: white;
		}
	}

	.checklist-content{
		color: white;

		

		.additional-info{
			color: white;
		}
	}

}

.builder-checklist .checklist-status-selected{
	background:none !important;

	/* stylelint-disable-next-line no-descending-specificity */
	.checklist-content{
		//padding: .5rem;
		font-weight:bold;
		//color: #5972B7 !important;
		color:white;
		background: $color-blue;
		border-radius: 10px;

		.checkbox{
			//background: #5972B7 !important;
			background: white;
		}

	}

	.chevy{
		svg path{
			//fill: #5972B7 !important;
			fill: $color-blue !important;
		}
	}
}

.builder-checklist .subcheck-selected{
	background:none !important;

	/* stylelint-disable-next-line no-descending-specificity */
	.checklist-content{
		//padding: .5rem;
		font-weight:bold;
		color: #5972B7 !important;
		background: #DEE2EE;
		border-radius: 10px;

		.checkbox{
			background: #5972B7 !important;
		}

	}

	.chevy{
		svg path{
			fill: $color-blue !important;
		}
	}
}

	
