/* stylelint-disable color-hex-length */
/* stylelint-disable scss/dollar-variable-empty-line-before */
/* stylelint-disable scss/dollar-variable-colon-space-after */

/**
*  COLORS FOR HXP
*/

$color-coral:           #CE5143FF; // hsl(006, 059%, 054%);
$color-mint:            #C7D9C8FF; // hsl(123, 019%, 082%);
$color-off-mint:        #8FC1BBFF; // hsl(173, 029%, 066%);
$color-dark-green:      #1E3D3EFF; // hsl(182, 035%, 018%);
$color-light-tan:       #F7F6F1FF; // hsl(050, 027%, 096%);
$color-sand:            #E1DFD4FF; // hsl(051, 018%, 086%);
$color-tan:             #C4B197FF; // hsl(035, 028%, 068%);
$color-white:           #FEFFFDFF; // hsl(090, 100%, 100%);
$color-orange-cream:    #FFC58FFF; // hsl(029, 100%, 078%);

$color-red:             #CE5142FF;
$color-blue:            #5972B7FF;
$color-blue-grey:       #8A94ADFF;
$text-button-mint:      #33B7A7FF;
$color-grey-b1:         #B1B1B1FF;

$color-link:            #57C3FAFF;

$color-tuscan-red:      #8A4D4DFF; /* hsl( 000, 044, 054 ) brick red    */
$color-dark-coral:      #CE5142FF; /* hsl( 006, 068, 081 ) = HXP Red    */
$color-error:           #D7612FFF;
$color-mac-n-cheese:    #FFC58FFF; /* hsl( 029, 044, 100 ) = Creamsicle */
$color-khaki:           #C4B197FF; /* hsl( 035, 028, 068 ) = Tan        */
$color-bronze:          #A59E5EFF; /* hsl( 054, 043, 065 ) = Goldenrod  */
$color-raw-umber:       #797143FF; /* hsl( 051, 045, 047 ) New Tan      */
$color-mint:            #C7D9C8FF; /* hsl( 123, 008, 085 ) = Mint       */
$color-drk-sea-green:   #99BA9AFF; /* hsl( 122, 018, 073 ) mint shadow  */
$color-deep-moss-green: #3A583BFF; /* hsl( 124, 034, 035 ) Green        */
$color-cambridge-blue:  #8FC1BBFF; /* hsl( 173, 026, 076 ) Off Mint     */
$color-midnight-green:  #1E3D3EFF; /* hsl( 189, 081, 028 ) green dark   */
$color-cool-grey:       #8A94ADFF; /* hsl( 223, 020, 068 ) grey blue    */
$color-denim:           #5972B7FF; /* hsl( 224, 051, 072 ) = Blue       */

$color-nearly-white:    #FEFFFDFF; /* hsl( 090, 001, 100 ) nearly white */
$color-cultred:         #F7F6F1FF; /* hsl( 050, 002, 097 ) Cloud        */
$color-input-grey:      #F4F4F4FF; /* hsl( 000, 000, 096 ) Input Grey   */
$color-platinum:        #E4E4E4FF; /* hsl( 359, 000, 089 ) Outline      */
$color-rich-black:      #000013FF; /* hsl( 000, 000, 000 ) nearly black */
$color-black:           #000000FF;
$color-card-shadow:     #0000000D;
$color-shadow-soft:     #00000024;
$color-shadow-popup:    #00000044;

$color-primary:   $color-dark-coral;
$color-secondary:      $color-denim;
$color-tertiary:       $color-khaki;


$mobile-width:          500px;
$shadow-soft:  0 6px 18px 0 $color-shadow-soft;

$border-1-b1: 1px solid $color-grey-b1;

$box-shadow-0-4-6-0: 0 4px 6px 0 $color-shadow-popup;

// Added Nov 15 and later

// $color-gray-a3: #A3A3A3FF; use gray-b1 instead


.clear-cart-warning{
	width: 400px;
	margin-bottom: 2rem;

	.clear-cart-header{
		margin-bottom: 1rem;
		font-size: 2.5rem;
		text-align: center;
	}

	.clear-cart-message{
		font-size: 1.6rem;

	}
}

.edit-builder-nav{
	.delete-builder{
		display: flex;
		justify-content: center;
		width: 100%;
		margin-top: 1rem;
		font-size: 2rem;
		color: $color-blue-grey;
		cursor: pointer;
	}

	.navbar-input{
		position: relative;

		.input-overlay{
			position: absolute;
			z-index:5;
			width: 100%;
			height: 100%;
		}
	}
}

.login-warning,
.builder-warning,
.waitlist-warning,
.indication-warning {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 15;
	width: 100%;
	height: 100%;
	background: #0000001a;

	.warning-body{
		position: absolute;
		top: 50%;
		left: 50%;
		width: 400px;
		padding: 2rem 3rem;
		background: white;
		border-radius: 2rem;
		transform: translate(-50%, -50%);

		.header{
			font-size: 3rem;
			text-align: center;
		}

		.text-body{
			display: flex;
			flex-direction: column;
			gap: 1rem;
			margin: 3rem 0;
			font-size: 2rem;
		}

		.actions{
			display: flex;
			justify-content: space-evenly;
		}

		.close {
			position: absolute;
			top: 0;
			right: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 30px;
			height: 30px;
			font-size: 3rem;
			cursor: pointer;
			background: white;
			border: 1px solid black;
			border-radius: 50%;
		}

		.sign-up {
			display: flex;
			justify-content: center;
			padding: 1rem;
			font-size: 2rem;
			color: #2096F2;
			cursor: pointer;
		}
	}
}

.invalid-delete{
	position:fixed;
	z-index:55;
	top:0;
	left:0;
	width: 100vw;
	height:100vh;
}
