/* stylelint-disable color-hex-length */
/* stylelint-disable scss/dollar-variable-empty-line-before */
/* stylelint-disable scss/dollar-variable-colon-space-after */

/**
*  COLORS FOR HXP
*/

$color-coral:           #CE5143FF; // hsl(006, 059%, 054%);
$color-mint:            #C7D9C8FF; // hsl(123, 019%, 082%);
$color-off-mint:        #8FC1BBFF; // hsl(173, 029%, 066%);
$color-dark-green:      #1E3D3EFF; // hsl(182, 035%, 018%);
$color-light-tan:       #F7F6F1FF; // hsl(050, 027%, 096%);
$color-sand:            #E1DFD4FF; // hsl(051, 018%, 086%);
$color-tan:             #C4B197FF; // hsl(035, 028%, 068%);
$color-white:           #FEFFFDFF; // hsl(090, 100%, 100%);
$color-orange-cream:    #FFC58FFF; // hsl(029, 100%, 078%);

$color-red:             #CE5142FF;
$color-blue:            #5972B7FF;
$color-blue-grey:       #8A94ADFF;
$text-button-mint:      #33B7A7FF;
$color-grey-b1:         #B1B1B1FF;

$color-link:            #57C3FAFF;

$color-tuscan-red:      #8A4D4DFF; /* hsl( 000, 044, 054 ) brick red    */
$color-dark-coral:      #CE5142FF; /* hsl( 006, 068, 081 ) = HXP Red    */
$color-error:           #D7612FFF;
$color-mac-n-cheese:    #FFC58FFF; /* hsl( 029, 044, 100 ) = Creamsicle */
$color-khaki:           #C4B197FF; /* hsl( 035, 028, 068 ) = Tan        */
$color-bronze:          #A59E5EFF; /* hsl( 054, 043, 065 ) = Goldenrod  */
$color-raw-umber:       #797143FF; /* hsl( 051, 045, 047 ) New Tan      */
$color-mint:            #C7D9C8FF; /* hsl( 123, 008, 085 ) = Mint       */
$color-drk-sea-green:   #99BA9AFF; /* hsl( 122, 018, 073 ) mint shadow  */
$color-deep-moss-green: #3A583BFF; /* hsl( 124, 034, 035 ) Green        */
$color-cambridge-blue:  #8FC1BBFF; /* hsl( 173, 026, 076 ) Off Mint     */
$color-midnight-green:  #1E3D3EFF; /* hsl( 189, 081, 028 ) green dark   */
$color-cool-grey:       #8A94ADFF; /* hsl( 223, 020, 068 ) grey blue    */
$color-denim:           #5972B7FF; /* hsl( 224, 051, 072 ) = Blue       */

$color-nearly-white:    #FEFFFDFF; /* hsl( 090, 001, 100 ) nearly white */
$color-cultred:         #F7F6F1FF; /* hsl( 050, 002, 097 ) Cloud        */
$color-input-grey:      #F4F4F4FF; /* hsl( 000, 000, 096 ) Input Grey   */
$color-platinum:        #E4E4E4FF; /* hsl( 359, 000, 089 ) Outline      */
$color-rich-black:      #000013FF; /* hsl( 000, 000, 000 ) nearly black */
$color-black:           #000000FF;
$color-card-shadow:     #0000000D;
$color-shadow-soft:     #00000024;
$color-shadow-popup:    #00000044;

$color-primary:   $color-dark-coral;
$color-secondary:      $color-denim;
$color-tertiary:       $color-khaki;


$mobile-width:          500px;
$shadow-soft:  0 6px 18px 0 $color-shadow-soft;

$border-1-b1: 1px solid $color-grey-b1;

$box-shadow-0-4-6-0: 0 4px 6px 0 $color-shadow-popup;

// Added Nov 15 and later

// $color-gray-a3: #A3A3A3FF; use gray-b1 instead

$navbar-padding: 2rem;

.registration-navbar{
	z-index:10;
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	padding-right: 0;
}

.registration-navbar .nav-header{
	position:relative;
	display:flex;
	flex-direction: column;
	width: calc(100% - 2 * $navbar-padding);
	height: 14rem;
	padding: $navbar-padding;
	color: $color-white;
	background-image: url('@/resources/nav-header.svg');
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;

	.hxp-logo{
		display:flex;
		align-items: flex-start;
		justify-content: flex-start;
		width: 100%;
		height: 100%;

		svg{
			height:100%;
		}
	}
}

.registration-navbar .nav-content{
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	overflow: hidden;
}

.registration-navbar .nav-content .builder-info-wrapper{
	position: relative;
	display:flex;
	flex-basis: 18rem;
	flex-direction: column;
	flex-shrink: 0;
	justify-content: space-between;
	padding:2rem;
	background: #F0ECE7;

	@media (width < 500px) {
		flex-basis: 14rem;
	}

	.registration-rect{
		position: absolute;
		top:0;
		right:0;
	}
}

.registration-navbar .nav-content .builder-info-wrapper .profile-info{
	display:flex;
	flex-direction: column;

	.profile-info-wrapper{
		display: flex;
		align-items: center;

		.profile-icon{
			width: 65px;

			@media (width < 500px) {
				width: 45px;
			}
		}

		.profile-name{
			padding-left:2rem;

			.current-user-header{
				font-size: 1.6rem;
			}

			.current-user{
				font-size: 2.6rem;
				font-weight: 400;
			}
		}
	}
}

.registration-navbar .nav-content .builder-info-wrapper .progress-wrapper{
	display:flex;
	flex-direction: column;
	width:100%;
	margin-top:1rem;

	.progress{
		width:90%;
		padding:1.5rem;
		accent-color: $color-red;
	}



	.percent{
		padding-top:0;
		margin-top:0;
		font-family: 'Poppins';
		font-size: 1.8rem;
		font-weight: bold;
	}
}

.registration-navbar .nav-content .builder-info-wrapper .swap-builder-wrapper{
	position: relative;

	.swap-builder{
		font-size: 1.8rem;
		font-weight:bold;
		color: $color-blue;

		&:hover{
			cursor: pointer;
		}
	}

	.builders{
		position: absolute;
		top:0;
		z-index:2;
		width: 100%;
		-webkit-box-shadow: 0px 5px 5px 0px rgba(55, 54, 54, 0.75);
		-moz-box-shadow: 0px 5px 5px 0px rgba(55, 54, 54, 0.75);
		box-shadow: 0px 5px 5px 0px rgba(55, 54, 54, 0.75);

		.builder{
			padding:1.5rem;
			font-size: 2rem;
			font-weight: bold;
			color:black;
			background:white;

			&:hover{
				cursor: pointer;
			}
		}

		.selected{
			color: white;
			background: $color-blue;
		}

		.builder:hover:not(.selected){
			background: lightgrey;
		}
	}
}


.registration-navbar .nav-content .checklist-wrapper{
	overflow: hidden auto;
}

.registration-navbar .button-container{
	padding: $navbar-padding;
	margin-bottom: 2.5rem;

	.whats-next-button{
		margin-bottom: 2rem;
	}

	.back-to-cart-button, .whats-next-button{
		display:flex;
		gap: 2rem;
		align-items: center;

		//justify-content: center;

		&:hover{
			cursor: pointer;
		}

		.icon{
			width:20px;
			height:20px;
		}

		.cart-img svg{
			fill:black;
		}

		.text{
			font-family: 'vista-slab', 'Minion Variable Concept', sans-serif;
			font-size: 1.8rem;
		}
	}

}
