/* stylelint-disable color-hex-length */
/* stylelint-disable scss/dollar-variable-empty-line-before */
/* stylelint-disable scss/dollar-variable-colon-space-after */

/**
*  COLORS FOR HXP
*/

$color-coral:           #CE5143FF; // hsl(006, 059%, 054%);
$color-mint:            #C7D9C8FF; // hsl(123, 019%, 082%);
$color-off-mint:        #8FC1BBFF; // hsl(173, 029%, 066%);
$color-dark-green:      #1E3D3EFF; // hsl(182, 035%, 018%);
$color-light-tan:       #F7F6F1FF; // hsl(050, 027%, 096%);
$color-sand:            #E1DFD4FF; // hsl(051, 018%, 086%);
$color-tan:             #C4B197FF; // hsl(035, 028%, 068%);
$color-white:           #FEFFFDFF; // hsl(090, 100%, 100%);
$color-orange-cream:    #FFC58FFF; // hsl(029, 100%, 078%);

$color-red:             #CE5142FF;
$color-blue:            #5972B7FF;
$color-blue-grey:       #8A94ADFF;
$text-button-mint:      #33B7A7FF;
$color-grey-b1:         #B1B1B1FF;

$color-link:            #57C3FAFF;

$color-tuscan-red:      #8A4D4DFF; /* hsl( 000, 044, 054 ) brick red    */
$color-dark-coral:      #CE5142FF; /* hsl( 006, 068, 081 ) = HXP Red    */
$color-error:           #D7612FFF;
$color-mac-n-cheese:    #FFC58FFF; /* hsl( 029, 044, 100 ) = Creamsicle */
$color-khaki:           #C4B197FF; /* hsl( 035, 028, 068 ) = Tan        */
$color-bronze:          #A59E5EFF; /* hsl( 054, 043, 065 ) = Goldenrod  */
$color-raw-umber:       #797143FF; /* hsl( 051, 045, 047 ) New Tan      */
$color-mint:            #C7D9C8FF; /* hsl( 123, 008, 085 ) = Mint       */
$color-drk-sea-green:   #99BA9AFF; /* hsl( 122, 018, 073 ) mint shadow  */
$color-deep-moss-green: #3A583BFF; /* hsl( 124, 034, 035 ) Green        */
$color-cambridge-blue:  #8FC1BBFF; /* hsl( 173, 026, 076 ) Off Mint     */
$color-midnight-green:  #1E3D3EFF; /* hsl( 189, 081, 028 ) green dark   */
$color-cool-grey:       #8A94ADFF; /* hsl( 223, 020, 068 ) grey blue    */
$color-denim:           #5972B7FF; /* hsl( 224, 051, 072 ) = Blue       */

$color-nearly-white:    #FEFFFDFF; /* hsl( 090, 001, 100 ) nearly white */
$color-cultred:         #F7F6F1FF; /* hsl( 050, 002, 097 ) Cloud        */
$color-input-grey:      #F4F4F4FF; /* hsl( 000, 000, 096 ) Input Grey   */
$color-platinum:        #E4E4E4FF; /* hsl( 359, 000, 089 ) Outline      */
$color-rich-black:      #000013FF; /* hsl( 000, 000, 000 ) nearly black */
$color-black:           #000000FF;
$color-card-shadow:     #0000000D;
$color-shadow-soft:     #00000024;
$color-shadow-popup:    #00000044;

$color-primary:   $color-dark-coral;
$color-secondary:      $color-denim;
$color-tertiary:       $color-khaki;


$mobile-width:          500px;
$shadow-soft:  0 6px 18px 0 $color-shadow-soft;

$border-1-b1: 1px solid $color-grey-b1;

$box-shadow-0-4-6-0: 0 4px 6px 0 $color-shadow-popup;

// Added Nov 15 and later

// $color-gray-a3: #A3A3A3FF; use gray-b1 instead

.trip-assigment-popup-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	background: #00000074;

}

.trip-assigment-popup-wrapper .trip-assigment-popup {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 2rem;
	max-height: 100%;
	overflow-y: auto;
	padding: 4rem 8rem;
	padding-bottom: 4rem;
	background: $color-white;
	border-radius: 1rem;


	.close-button {
		position: absolute;
		top: 2rem;
		right: 2rem;

		&:hover {
			cursor: pointer;
		}

	}

	.trip-header-wrapper {
		.trip-assignment-header {
			font-family: Poppins, sans-serif;
			font-size: 3.2rem;
		}

		.trip-assignment-subheader {
			font-family: Poppins, sans-serif;
			font-size: 1.8rem;
		}
	}

	.trip-table-wrapper {
		.table-header {
			padding-bottom: 1rem;
			font-size: 1.6rem;
			border-bottom: 3px solid #DEDEDE;
		}
	}


}

.trip-assigment-popup-wrapper .trip-assigment-popup .builder-statuses {
	width: 100%;
	font-size: 1.6rem;
	border-collapse: collapse;

	tr,
	td {
		height: 4rem;
		padding: 2px 20px;
		border-bottom: 3px solid #DEDEDE;
	}

	th {
		padding: 0px 20px;
		font-weight: 700;
	}

	tr {
		font-weight: 200;
	}

	tr td:first-child, th:first-child{
		padding-left: 0px;
	}
	
	tr td:last-child, th:first-child{
		padding-right: 0px;
	}


	.faded {
		color: #C2C2C2;
	}

	.bolded {
		font-weight: bold;
	}

	@keyframes important-notice {
		100% {
			opacity: 0.25;
		}
	}

	.important {
		position: relative;
		
		&::before {
			position: absolute;
			left: 0%;
			width: 1.75ex;
			height: 1.75ex;
			content: "";
			background: orange;
			border-radius: 100%;
			transform: translateX(calc(-100% - 0.5rem)) translateY(.25ex);
			animation: important-notice 1s linear infinite alternate;
		}
	}



	.bolded-link {
		font-weight: bold;
		color: $color-blue;
	}

	.link {
		color: $color-blue;
	}

	.clickable:hover {
		cursor: pointer;
	}
}

.trip-assigment-popup-wrapper .trip-assigment-popup .btn-wrapper {
	display: flex;
	justify-content: center;
	width: 100%;

	.okay {
		width: 153px;
		padding: 1rem;
		font-size: 2rem;
		font-weight: bold;
		color: white;
		background: $color-blue;
		border-radius: 2rem;

	}
}


@media only screen and (width < 1100px){
	.trip-assigment-popup-wrapper .trip-assigment-popup {
		padding:4rem;
	}
	.trip-assigment-popup-wrapper .trip-assigment-popup .builder-statuses {
		font-size: 1.4rem;

	}
}

@media only screen and (width < 500px) {
    .trip-assigment-popup-wrapper .trip-assigment-popup{
		width: unset;
		min-width: unset;
		max-width: 100%;
		padding: 1rem;
		margin: 0 5px;
    }

	.trip-assigment-popup-wrapper .trip-assigment-popup .builder-statuses{
		font-size: 1.4rem;
	}
}
