.g-label{
    position: absolute;
    top:32%;
    //left: 4%;
	left: 1rem;
	font-weight: 300;
	font-size: 1.6rem;
    pointer-events: none;
}

.g-label-active{
    top: -25% !important;
    font-size: 1.6rem;
	font-weight: 300;
	background:white;
	padding-left: 2px;
	padding-right: 2px;
}

.g-input{
    position: relative;
    width: calc(100% - 3.5rem);
	border: 2px solid #E4E4E4;
	height: 2.5rem;
	padding:1rem;
	font-size: 1.6rem;
	padding-left:1.5rem;
}

.g-input-wrapper{
    position:relative;
	font-family: 'vista-slab', 'Minion Variable Concept', sans-serif;

    &:focus-within{
        label{
			top: -25% !important;
			font-size: 1.6rem;
			background:white;
			padding-left: 2px;
			padding-right: 2px;
			font-weight: 300;
        }
    }

    input:focus{
        /* stylelint-disable-next-line declaration-no-important */
        outline: none !important;
    }
}


.g-textarea-wrapper{
	position:relative;
	font-family: 'vista-slab', 'Minion Variable Concept', sans-serif;
}

.g-textarea{
	box-sizing: border-box;
	padding:1.5rem;
	padding-left: 1.5rem;
	overflow-y:hidden;
	resize:none;
	border: 2px solid #E4E4E4;
	width:100%
}

.g-textarea-label{
	position: absolute;
	top: -1.25rem;
	background:white;
	font-size: 1.6rem;
	background:white;
	left: 1rem;
	font-weight: 300;
}


@media only screen and (max-width: 500px) {
	.g-label-active{
		background: rgba(255, 255, 255, 0.8);
	}
}