/* stylelint-disable color-hex-length */
/* stylelint-disable scss/dollar-variable-empty-line-before */
/* stylelint-disable scss/dollar-variable-colon-space-after */

/**
*  COLORS FOR HXP
*/

$color-coral:           #CE5143FF; // hsl(006, 059%, 054%);
$color-mint:            #C7D9C8FF; // hsl(123, 019%, 082%);
$color-off-mint:        #8FC1BBFF; // hsl(173, 029%, 066%);
$color-dark-green:      #1E3D3EFF; // hsl(182, 035%, 018%);
$color-light-tan:       #F7F6F1FF; // hsl(050, 027%, 096%);
$color-sand:            #E1DFD4FF; // hsl(051, 018%, 086%);
$color-tan:             #C4B197FF; // hsl(035, 028%, 068%);
$color-white:           #FEFFFDFF; // hsl(090, 100%, 100%);
$color-orange-cream:    #FFC58FFF; // hsl(029, 100%, 078%);

$color-red:             #CE5142FF;
$color-blue:            #5972B7FF;
$color-blue-grey:       #8A94ADFF;
$text-button-mint:      #33B7A7FF;
$color-grey-b1:         #B1B1B1FF;

$color-link:            #57C3FAFF;

$color-tuscan-red:      #8A4D4DFF; /* hsl( 000, 044, 054 ) brick red    */
$color-dark-coral:      #CE5142FF; /* hsl( 006, 068, 081 ) = HXP Red    */
$color-error:           #D7612FFF;
$color-mac-n-cheese:    #FFC58FFF; /* hsl( 029, 044, 100 ) = Creamsicle */
$color-khaki:           #C4B197FF; /* hsl( 035, 028, 068 ) = Tan        */
$color-bronze:          #A59E5EFF; /* hsl( 054, 043, 065 ) = Goldenrod  */
$color-raw-umber:       #797143FF; /* hsl( 051, 045, 047 ) New Tan      */
$color-mint:            #C7D9C8FF; /* hsl( 123, 008, 085 ) = Mint       */
$color-drk-sea-green:   #99BA9AFF; /* hsl( 122, 018, 073 ) mint shadow  */
$color-deep-moss-green: #3A583BFF; /* hsl( 124, 034, 035 ) Green        */
$color-cambridge-blue:  #8FC1BBFF; /* hsl( 173, 026, 076 ) Off Mint     */
$color-midnight-green:  #1E3D3EFF; /* hsl( 189, 081, 028 ) green dark   */
$color-cool-grey:       #8A94ADFF; /* hsl( 223, 020, 068 ) grey blue    */
$color-denim:           #5972B7FF; /* hsl( 224, 051, 072 ) = Blue       */

$color-nearly-white:    #FEFFFDFF; /* hsl( 090, 001, 100 ) nearly white */
$color-cultred:         #F7F6F1FF; /* hsl( 050, 002, 097 ) Cloud        */
$color-input-grey:      #F4F4F4FF; /* hsl( 000, 000, 096 ) Input Grey   */
$color-platinum:        #E4E4E4FF; /* hsl( 359, 000, 089 ) Outline      */
$color-rich-black:      #000013FF; /* hsl( 000, 000, 000 ) nearly black */
$color-black:           #000000FF;
$color-card-shadow:     #0000000D;
$color-shadow-soft:     #00000024;
$color-shadow-popup:    #00000044;

$color-primary:   $color-dark-coral;
$color-secondary:      $color-denim;
$color-tertiary:       $color-khaki;


$mobile-width:          500px;
$shadow-soft:  0 6px 18px 0 $color-shadow-soft;

$border-1-b1: 1px solid $color-grey-b1;

$box-shadow-0-4-6-0: 0 4px 6px 0 $color-shadow-popup;

// Added Nov 15 and later

// $color-gray-a3: #A3A3A3FF; use gray-b1 instead

.login-loader{
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.1);
	border-radius: 2rem;

	span{
		font-size: 5rem;
	}
}

.login-view{
	width:100%;
	height: 100%;
	border-radius: 2rem;
}

.login-view .signup-container{
	display:flex;
	align-items: center;
	justify-content: space-around;
	width:100%;
	//height: clamp(120px , 150px, 180px);
	height: 20%;
	background: $color-red;
	border-radius: 20px 20px 0 0;

}

.login-view .signup-container .left{
	display:flex;
	flex-direction: column;
	align-items: center;
	padding: 1rem;
	font-family: 'Poppins', sans-serif;

	.heading{
		font-size: clamp(34px, 38px, 42px);
		color: $color-white;
	}

	.sub-headings{
		display:flex;
		flex-direction: column;
		text-align: center;
		
		.sub-heading{
			font-size: clamp(20px, 24px, 28px);
			color: $color-white;
			 line-height:1;

		}
	}

}

.login-view .signup-container .right{
	.signup{
		width: 180px;
		background: $color-white;
		border: unset;
		color: $color-red;
		padding: 1.25rem;
		border-radius: 20px;
	}
}

.login-view #signin{
	display:flex;
	flex-direction: column;
	gap: 2rem;
	align-items: center;
	width: calc(100% - 2.5rem);
	height: calc(80% - 2.5rem);
	padding: 1.25rem;

	.title{
		width: 75%;
		margin-top: 7%;
	}

	.input-container{
		display:flex;
		flex-direction: column;
		gap: 2rem;
		width: 75%;

		.email,.password{
			width: calc(100% - 4rem);
			height:calc(60px - 2rem);
			padding: 1rem 2rem;
			font-size: 2.4rem;
			font-weight: 300;
			background: $color-input-grey;
			border:unset;
			border-radius: 8px;
		}

		.forgot-password .forgot{
			font-family: 'Poppins', sans-serif;
			background-color: unset;
			border: unset;
		}
	}

	.login-btn-container{
		.signin{
			width: 180px;
			padding: 1.25rem;
			color: $color-white;
			background: $color-red;
			border: unset;
			border-radius: 20px;

			&:disabled{
			   background:#aaaaaa;
				cursor: not-allowed;
			 }
		}
	}

}

@media only screen and (max-width: 500px) {
	.login-view{
		width: 100%;
		height: 100%;
	}

	.login-view .signup-container{
		display:flex;
		flex-direction:column;
		justify-content: space-around;
		height: calc(30% - 2.5rem);
		border-radius: 0px;
		padding:0;
		margin:0;

		.close{
			position: absolute;
			top:2rem;
			right: 2rem;
		}

		.left{
			padding:0;
			margin:0;

			.heading{
				font-size: 3rem;
			}

			.sub-headings .sub-heading{
				font-size: 2rem;
			}
		}

		.right{
			padding:0;
			margin:0;

			.signup{
				padding: 1rem;
				font-size: 2rem; 
			}

		}
	}
	
	.login-view #signin{
		height: 70%;
		
		.title{
			width:95%;
			font-size: 30px;

		}

		.input-container{
			width:95%;

			.email, .password{
				font-size: 1.8rem;
			}
			
		}

		.login-btn-container{
			position: absolute;
			bottom: 10%;
			padding: 1rem;
			font-size: 2rem; 
		}
	}



}

