/* stylelint-disable color-hex-length */
/* stylelint-disable scss/dollar-variable-empty-line-before */
/* stylelint-disable scss/dollar-variable-colon-space-after */

/**
*  COLORS FOR HXP
*/

$color-coral:           #CE5143FF; // hsl(006, 059%, 054%);
$color-mint:            #C7D9C8FF; // hsl(123, 019%, 082%);
$color-off-mint:        #8FC1BBFF; // hsl(173, 029%, 066%);
$color-dark-green:      #1E3D3EFF; // hsl(182, 035%, 018%);
$color-light-tan:       #F7F6F1FF; // hsl(050, 027%, 096%);
$color-sand:            #E1DFD4FF; // hsl(051, 018%, 086%);
$color-tan:             #C4B197FF; // hsl(035, 028%, 068%);
$color-white:           #FEFFFDFF; // hsl(090, 100%, 100%);
$color-orange-cream:    #FFC58FFF; // hsl(029, 100%, 078%);

$color-red:             #CE5142FF;
$color-blue:            #5972B7FF;
$color-blue-grey:       #8A94ADFF;
$text-button-mint:      #33B7A7FF;
$color-grey-b1:         #B1B1B1FF;

$color-link:            #57C3FAFF;

$color-tuscan-red:      #8A4D4DFF; /* hsl( 000, 044, 054 ) brick red    */
$color-dark-coral:      #CE5142FF; /* hsl( 006, 068, 081 ) = HXP Red    */
$color-error:           #D7612FFF;
$color-mac-n-cheese:    #FFC58FFF; /* hsl( 029, 044, 100 ) = Creamsicle */
$color-khaki:           #C4B197FF; /* hsl( 035, 028, 068 ) = Tan        */
$color-bronze:          #A59E5EFF; /* hsl( 054, 043, 065 ) = Goldenrod  */
$color-raw-umber:       #797143FF; /* hsl( 051, 045, 047 ) New Tan      */
$color-mint:            #C7D9C8FF; /* hsl( 123, 008, 085 ) = Mint       */
$color-drk-sea-green:   #99BA9AFF; /* hsl( 122, 018, 073 ) mint shadow  */
$color-deep-moss-green: #3A583BFF; /* hsl( 124, 034, 035 ) Green        */
$color-cambridge-blue:  #8FC1BBFF; /* hsl( 173, 026, 076 ) Off Mint     */
$color-midnight-green:  #1E3D3EFF; /* hsl( 189, 081, 028 ) green dark   */
$color-cool-grey:       #8A94ADFF; /* hsl( 223, 020, 068 ) grey blue    */
$color-denim:           #5972B7FF; /* hsl( 224, 051, 072 ) = Blue       */

$color-nearly-white:    #FEFFFDFF; /* hsl( 090, 001, 100 ) nearly white */
$color-cultred:         #F7F6F1FF; /* hsl( 050, 002, 097 ) Cloud        */
$color-input-grey:      #F4F4F4FF; /* hsl( 000, 000, 096 ) Input Grey   */
$color-platinum:        #E4E4E4FF; /* hsl( 359, 000, 089 ) Outline      */
$color-rich-black:      #000013FF; /* hsl( 000, 000, 000 ) nearly black */
$color-black:           #000000FF;
$color-card-shadow:     #0000000D;
$color-shadow-soft:     #00000024;
$color-shadow-popup:    #00000044;

$color-primary:   $color-dark-coral;
$color-secondary:      $color-denim;
$color-tertiary:       $color-khaki;


$mobile-width:          500px;
$shadow-soft:  0 6px 18px 0 $color-shadow-soft;

$border-1-b1: 1px solid $color-grey-b1;

$box-shadow-0-4-6-0: 0 4px 6px 0 $color-shadow-popup;

// Added Nov 15 and later

// $color-gray-a3: #A3A3A3FF; use gray-b1 instead

.navbar{
    width: v-bind(navbarWidth);
    max-width: 375px;
    height: calc(100% - v-bind(headerHeight));
    z-index: 6;
    //background: rgba(249, 248, 244, 0.75);
    background-color: #F9F8F4;
    position: fixed;
    top:v-bind(headerHeight);
    left:0;
    bottom:0;
    //overflow-y: auto;

    transition: width 0.3s ease, transform 0.3s ease, opacity 0.3s ease;


    .builder-header{
        left: calc(100% - 5.7rem);
        position: absolute;
        z-index: 5;
    }


    .stamp-icon{
        position: absolute;
        top: 20px;
        right: -75px;

        cursor: pointer;
        transition: transform 0.6s ease;

        .close-text {
            display: flex;
            justify-content: center;
            padding: 3px 5px;
            font-size: 16px;
            background-color: white;
            border: 1px solid black;
            border-radius: 0.5rem;
        }
    }
    &.collapsed .stamp-icon{
        transform: rotate(-360deg);
    }
    .navbar-content-container {
        width: 100%;
        height: 100%;


        &::-webkit-scrollbar {
            width: 1em;
            background: none;
            position: relative;
            &-track{
                background: rgb(156, 156, 156, 0.25);
                border-radius: .5em;
            }
            &-thumb {
                background: rgb(156, 156, 156, 0.75);
                width: 1em;
                border-radius: .5em;
            }
        }
    }
    .navbar-content{
        height: 100%;
        .version {
            position: absolute;
            bottom: 0;
            font-size: 1.5em;
            font-weight: lighter;
            width: calc(100% - 11px);
            //margin: 1em;
            padding:5px;
            // pointer-events: none;
            color: rgba(193, 191, 191, 0.82);
            background: $color-light-tan;
        }
    }
    .navbar-collapsed-container{
        position: absolute;
        top: 200px;
        left: 40px;
        overflow-x: hidden;
        cursor: pointer;
    }
}

.scrim-close {
    position: fixed;
    /* stylelint-disable-next-line value-keyword-case */
    top: v-bind(headerHeight);
    left: 400px;
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 1rem;
    padding-left: 1rem;
    font-size: 1.6rem;
    color: white;
    pointer-events: none;

    @media (width < 500px) {
        left: 80%;
        width: 20%;
        padding-left: unset;
    }

    .chevy {
        display: flex;
        place-items: center;
        height: 2.2rem;
    }

    .chevy svg {
        transform: rotate(90deg);
    }

    .chevy path {
        fill: white;
    }
}

@media only screen and (width <= 1050px) {
    .navbar{
        width: 400px;
        max-width: 400px;
        background: rgba(249, 248, 244);

        &.collapsed{
            opacity: 0;
            transform: translateX(-100%);

            .stamp-icon{
                position: absolute;
                top: -15px;
                left: -30px;
                transition: none;

                svg{
                    width: 120px;
                    height: 120px;
                }
            }

            .version {
                display: none;
            }
        }

        .navbar-collapsed-container{
            top: 17px;
            left: 17px;
            svg{
                height: 30px;
                width: 30px;
            }
        }
    }

    .scrim{
        position: fixed;
        z-index: 4;
        width:100%;
        height:100%;
        background-color: #000c;
    }
}

.scrim, .scrim-close {
    &.fade-enter-from, &.fade-leave-to {
        opacity: 0;
    }

    &.fade-enter-active, &.fade-leave-active {
        transition: opacity .25s ease;
    }
}

.scrim-close {
    &.fade-enter-from, &.fade-leave-to {
        top: 0;
    }

    &.fade-enter-active, &.fade-leave-active {
        transition: top .25s ease;
    }
}

@media only screen and (max-width: 500px) {
    .navbar{
        max-width: unset;
        width:100%;
        background: rgba(249, 248, 244);

        &.collapsed{
            opacity: 0;
            transform: translateX(-100%);

            .stamp-icon{
                position: absolute;
                top: -15px;
                left: -30px;
                transition: none;

                svg{
                    width: 120px;
                    height: 120px;
                }
            }

            .version {
                display: none;
            }
        }

        .navbar-collapsed-container{
            top: 17px;
            left: 17px;
            svg{
                height: 30px;
                width: 30px;
            }
        }
    }

    .registration, .builder{
        width: 80%;
    }
}
