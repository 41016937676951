@import url("https://use.typekit.net/kos4yoh.css");
$font-family-base: "vista-slab", serif;

body {
    margin: unset;
    box-sizing: border-box;
    font-family: $font-family-base;
}

body, html, #application-root {
    width: 100%;
    height: 100%;
}
// Safari has special padding it applies to lists that no one else does >:(
ul, ol {
    padding-inline-start: 0;
    margin-block-start: 0;
    margin-block-end: 0;
}

:root {
    font-size: 10px;
}
