.button-base{
    cursor: pointer;
    position: relative;
    width: 13rem;
    height: 3.5rem;
    font-family: $font-family-base;
    font-size: 1.3rem;
    background-color: transparent;
    border-color:     transparent;
    outline: 2px solid transparent;
    outline-offset: 2px;
    border-radius: 2rem;
    box-sizing: border-box;
    overflow: hidden;
    transition: $animation-button;

}

button.g-save {
    @extend .button-base;
    background-color: $color-blue;
    font-size: 2rem;
    color: $color-white;
    border: none;
    min-width: 9.6rem;
    height: 4rem;
    padding-inline: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-around;
    white-space: nowrap;
    font-weight: bold;
    &:hover{
        background-color: darken($color-blue, 10);
    }
    &:disabled{
        cursor: unset;
        color: #BABABA;
        background-color: #D3D3D3;
    }
}

button.g-delete{
    @extend .g-save;
    background-color: $color-red;
    
    &:hover{
        background-color: darken($color-red, 10);
    }
}

button.g-autowidth {
    width: unset;
}

button.g-center-text {
    justify-content: center;
    text-align: center;
}

button.g-warning {
    @extend .g-save;
    background-color: $color-coral;
    color: $color-light-tan;
    &:hover{
        background-color: darken($color-coral, 5);

    }
}

button.g-borderless {
    @extend .g-save;
    color: $color-blue-grey;
    border:none;
    background:unset;
    font-size: 2rem;

    &:hover{
        background-color: unset;
        color: darken($color-blue-grey, 5);
    }

}

button.g-add {
    @extend .button-base;
    background-color: $color-mint;
    font-size: 3.6rem;
    color: $color-dark-green;
    color: white;
    border: none;
    width: 3.5rem;
    height: 3.5rem;
    padding-bottom: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover{
        background-color: darken($color-mint, 0.2);
    }
    &:disabled{
        background-color: grey;
        cursor: unset;
    }
}

button.g-borderless {
    @extend .button-base;
}

.button-pair {
    display: flex;
    gap: .2rem;
    overflow: hidden;

    button {
        text-overflow: ellipsis;
    }

    button:first-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    button:not(:first-child, :last-child) {
        border-radius: unset;
    }
    
    button:last-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}

button:disabled {
    background:rgb(185, 185, 185) !important;
    color:rgb(161, 161, 161) !important;
}