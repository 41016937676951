$font-poppins: 'Poppins', sans-serif;
$font-vista: 'vista-slab', 'Minion Variable Concept', sans-serif;




%text-poppins{
	font-family: $font-poppins;
	font-size: 1.6rem;
}

%text-slab{
	font-family: $font-vista;
	font-size: 1.6rem;
}

.g-h1{
	@extend %text-poppins;

	font-size: 3.2rem;
	font-weight: $font-weight-bold;
}

.g-h2, .g-h2-reg{
	@extend %text-poppins;

	font-size: 2rem;
	font-weight: $font-weight-bold;
}

.g-h2-reg{
	font-weight: $font-weight-regular;
}

.g-sub-p{
	@extend %text-slab;

	font-size: 1.4rem;
	color: $color-grey-b1;
}

.g-p-btn{
	@extend %text-poppins;

	font-size: 1.4rem;
	font-weight: $font-weight-bold;
	color: $color-denim;
}

span.bold {
	font-weight: $font-weight-bold;
}