/* stylelint-disable color-hex-length */
/* stylelint-disable scss/dollar-variable-empty-line-before */
/* stylelint-disable scss/dollar-variable-colon-space-after */

/**
*  COLORS FOR HXP
*/

$color-coral:           #CE5143FF; // hsl(006, 059%, 054%);
$color-mint:            #C7D9C8FF; // hsl(123, 019%, 082%);
$color-off-mint:        #8FC1BBFF; // hsl(173, 029%, 066%);
$color-dark-green:      #1E3D3EFF; // hsl(182, 035%, 018%);
$color-light-tan:       #F7F6F1FF; // hsl(050, 027%, 096%);
$color-sand:            #E1DFD4FF; // hsl(051, 018%, 086%);
$color-tan:             #C4B197FF; // hsl(035, 028%, 068%);
$color-white:           #FEFFFDFF; // hsl(090, 100%, 100%);
$color-orange-cream:    #FFC58FFF; // hsl(029, 100%, 078%);

$color-red:             #CE5142FF;
$color-blue:            #5972B7FF;
$color-blue-grey:       #8A94ADFF;
$text-button-mint:      #33B7A7FF;
$color-grey-b1:         #B1B1B1FF;

$color-link:            #57C3FAFF;

$color-tuscan-red:      #8A4D4DFF; /* hsl( 000, 044, 054 ) brick red    */
$color-dark-coral:      #CE5142FF; /* hsl( 006, 068, 081 ) = HXP Red    */
$color-error:           #D7612FFF;
$color-mac-n-cheese:    #FFC58FFF; /* hsl( 029, 044, 100 ) = Creamsicle */
$color-khaki:           #C4B197FF; /* hsl( 035, 028, 068 ) = Tan        */
$color-bronze:          #A59E5EFF; /* hsl( 054, 043, 065 ) = Goldenrod  */
$color-raw-umber:       #797143FF; /* hsl( 051, 045, 047 ) New Tan      */
$color-mint:            #C7D9C8FF; /* hsl( 123, 008, 085 ) = Mint       */
$color-drk-sea-green:   #99BA9AFF; /* hsl( 122, 018, 073 ) mint shadow  */
$color-deep-moss-green: #3A583BFF; /* hsl( 124, 034, 035 ) Green        */
$color-cambridge-blue:  #8FC1BBFF; /* hsl( 173, 026, 076 ) Off Mint     */
$color-midnight-green:  #1E3D3EFF; /* hsl( 189, 081, 028 ) green dark   */
$color-cool-grey:       #8A94ADFF; /* hsl( 223, 020, 068 ) grey blue    */
$color-denim:           #5972B7FF; /* hsl( 224, 051, 072 ) = Blue       */

$color-nearly-white:    #FEFFFDFF; /* hsl( 090, 001, 100 ) nearly white */
$color-cultred:         #F7F6F1FF; /* hsl( 050, 002, 097 ) Cloud        */
$color-input-grey:      #F4F4F4FF; /* hsl( 000, 000, 096 ) Input Grey   */
$color-platinum:        #E4E4E4FF; /* hsl( 359, 000, 089 ) Outline      */
$color-rich-black:      #000013FF; /* hsl( 000, 000, 000 ) nearly black */
$color-black:           #000000FF;
$color-card-shadow:     #0000000D;
$color-shadow-soft:     #00000024;
$color-shadow-popup:    #00000044;

$color-primary:   $color-dark-coral;
$color-secondary:      $color-denim;
$color-tertiary:       $color-khaki;


$mobile-width:          500px;
$shadow-soft:  0 6px 18px 0 $color-shadow-soft;

$border-1-b1: 1px solid $color-grey-b1;

$box-shadow-0-4-6-0: 0 4px 6px 0 $color-shadow-popup;

// Added Nov 15 and later

// $color-gray-a3: #A3A3A3FF; use gray-b1 instead

.popup-wrapper{
	position:relative;
	//margin: 1rem 0;

	&:hover{
		cursor: pointer;
	}
}

.popup-container,.popup-container::after{
	box-sizing: border-box;
	display: flex;
	height: 100%;
	padding: 1rem 1rem 1rem 0.5rem;
	border: $border-1-b1;

}


.popup-container{
	align-items: center;
	width: calc(100% + 35px);
	//margin-left: 12px; // calc(25px / 2);
	background:$color-white;
	box-shadow: $box-shadow-0-4-6-0;
	&::after{
		position: absolute;
		right: -38px; // calc(-25px + (-25px / 2))
		width: 35px;
		content: "";
		background: $color-blue;
	}


}

.popup-container .popup-text{
	flex-direction: column;
	justify-content: center;
	width: 85%;
	height:100%;
	padding-left: .5rem;
	font-family: poppins, sans-serif;

	.popup-title{
		display:flex;
		align-items: center;
		width: 100%;
		font-size: 16px;

		p{
			margin: 0;
		}
	}

	.popup-subheader{
		width: 95%;
		font-size: 12px;
		font-weight: bold;

		p{
			margin: 0;
		}
	}
}

.popup-container .question-icon{
	position: absolute;
	right: 0; // right: 5%;
}

.sticky-note{
	position:fixed;
	top:30%;
	z-index:5; // left:5%;
}

.scrim{
	position:fixed;
	top:0;
	left:0;
	z-index:3;
	width:100%;
	height: 100%;
	background: #0006;
}

.popup-slot-text, .popup-subheader-text{
    padding-left: 1rem;
	margin-top: 1rem;
    font-family: vista-slab, serif;
    font-size: 20px;
	font-weight: 300;

	p{
		margin: 0;
	}
}

.popup-slot-text{
	display: flex;
	flex-direction: column;
	gap: 1rem;

	p{
		margin: 0;
	}
}

.popup-subheader-text{
    font-weight: 500;
}

@media only screen and ( width <= 500px) {
	.popup-container .popup-text{
		.popup-title{
			font-size:16px;
		}

		.popup-subheader{
			font-size: 12px;
		}
	}

	.popup-slot-text, .popup-subheader-text{
		font-size: 14px;
	}

	.sticky-note{
		position: fixed;
		inset: 0; // Linter wants 'inset' opposed to top,right,bottom,left = 0
		margin: auto;
	}

}
