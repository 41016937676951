$navbar-padding: 3rem;

.add-builder-nav .add-prev-builder {
	margin-left: $navbar-padding;
	text-decoration: underline;

	&:hover {
		cursor: pointer;
	}
}

.add-builder-nav {
    position: relative;
    display:flex;
    flex-direction: column;
    width: 100%;
    height: calc(100% - 2rem);

	.builder-details{
        display:flex;
        flex-basis: 0;
        flex-direction: column;
        flex-grow: 1;
        gap:3rem;
        height: calc(100% - 220px);
        padding-top:3rem;
        padding-bottom:1rem;
        overflow-x: hidden;
        overflow-y: auto;
    }

	.bottom-container{
		position: relative;
		display:flex;
		flex-basis: 0;
		flex-direction: column;
		gap: 3rem;
		justify-content: flex-end;
		width: 100%;
		height: 220px;
		padding-top: 1rem;
		padding-bottom: 1rem;

		.more-details {
			position: absolute;
			top: 0;
			box-sizing: border-box;
			width: 100%;
			padding: .25rem;
			font-size: 1.25em;
			font-weight: 600;
			text-align: center;
			text-shadow: 0 0 4px #FFFF;
			background: linear-gradient(to bottom, #0000, #0006); // backdrop-filter: blur(4px);
			transform: translateY(-100%);
		}

		.info{
			display: flex;
			flex-direction: column;
			gap: 1rem;
			align-items: center;
			justify-content: center;
			width: 100%;
			padding: 1rem 0;
			margin: 1rem 0;
			background: $color-blue;

			/* stylelint-disable-next-line max-nesting-depth */
			.text{
				max-width: 90%;
				font-size: 2rem;
				color: white;
				text-align: center;
			}
		}

		.button-container{
			display: flex;
			justify-content: space-evenly;

			/* stylelint-disable-next-line max-nesting-depth */
			button{
				width: unset;
				min-width: 13rem;
			}
		}
	}




	.label-container {
		display: flex;
		flex-direction: row;
	}

	.label{
		padding-right: 1rem;
		font-size: 12px;

		// padding-right: 2.5rem;
		// font-size: 1.5rem;
	}

	.select{
		width: 100%;
		padding: 1px 2px 1px 0;
		font-family: $font-poppins;
		font-size: 20px;
		font-weight: 500;
		color: $color-sand;
		background: none;
		border: none;
		border-bottom: 2px solid $color-black;
		outline: none;
		opacity: 1; /* Firefox */
		-webkit-appearance: none;

		&.val-selected {
			color: $color-black;
		}

		option{
			font-size: 1rem;
			font-size: 20px;
		}
	}

	select.select option,
	select.select option:disabled{
		color: $color-black;
	}

	option[value=""][disabled] {
		display: none;
	}

	.builder-details{
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		gap: 3rem;

		.header, .navbar-input, .sub-text{
			padding: 0 $navbar-padding;
		}

		.header{
			font-size: 2rem;
			font-weight: bold;
		}

		.sub-text{
			font-size: 2rem;
		}

		.wrong-age-wrapper{
			display: flex;
			flex-direction: column;
			gap:1rem;
			padding: 1rem;
			color:white;
			background: $color-blue;

			.header{
				margin:0;
			}

			p{
				margin: 0;
			}
		}

		.navbar-input{
			display: flex;
			gap: 1.5rem;


			/* stylelint-disable-next-line max-nesting-depth */
			.input-icon{
				display: flex;
				align-items: center;
				justify-content: center;
				width: 30px;
				height: 30px;
				/* stylelint-disable-next-line max-nesting-depth */
				svg{
					fill: $color-sand;
				}
			}



			/* stylelint-disable-next-line max-nesting-depth */
			.input-field{
				width: 265px;
				font-family: $font-poppins;

				/* stylelint-disable-next-line max-nesting-depth */
				.label-container{
					/* stylelint-disable-next-line max-nesting-depth */
					.label{
						font-size: 1.5rem;
					}
				}
				/* stylelint-disable-next-line max-nesting-depth */
				.input{
					width: 100%;
					padding: 1px 2px 1px 0;
					font-family: $font-poppins;
					font-size: 20px;
					font-weight: 500;
					color:$color-black;
					background: none;
					border: none;
					border-bottom: 2px solid $color-black;
					outline: none;

					/* stylelint-disable-next-line max-nesting-depth */
					&::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
						color: $color-sand; // $color-sand;
						opacity: 1; /* Firefox */
					}
					/* stylelint-disable-next-line max-nesting-depth */
					&:-ms-input-placeholder { /* Internet Explorer 10-11 */
						color: $color-sand; // $color-sand;
					}
					/* stylelint-disable-next-line max-nesting-depth */
					/* stylelint-disable-next-line selector-no-vendor-prefix */
					&::-ms-input-placeholder { /* Microsoft Edge */
						color: $color-sand; // $color-sand;
					}
				}

				/* stylelint-disable-next-line max-nesting-depth */
				.radio{
					display: flex;
					flex-direction: column;
					justify-content: center;
					accent-color: $color-red;
				/* stylelint-disable-next-line max-nesting-depth */
					.radio-option{
						display: flex;
						gap: 1rem;
						align-items: center;
						font-size: 1.5rem;

						// justify-content: center;
					}
				}
			}
			/* stylelint-disable-next-line max-nesting-depth */
			&.input-checkbox{
				/* stylelint-disable-next-line max-nesting-depth */
				.checkbox-input-field{
					display: flex;
					gap: 1rem;
					align-items: center;
					font-size: 1.5rem;
					/* stylelint-disable-next-line max-nesting-depth */
					&.disabled{
						pointer-events: none;
						opacity: 0.5;
					}
				}
			}

		}
		/* stylelint-disable-next-line max-nesting-depth */
		.input-checkbox{

			/* stylelint-disable-next-line max-nesting-depth */
			.tooltip--text{
				transform: translate(-130px, 15px);
			}
		}
	}

	.popup-section{
		width: calc(100% - 5rem);
		margin-left:2rem;

		.popups{
			width:95%;
		}
	}

}


.add-builder-nav .add-teen-builder{
	display:flex;
	flex-direction: column;
	justify-content: space-between;
	height:100%;
	padding:0;
	overflow-y: auto;

	// .builder-details{
	// 	.header{
	// 	}
	// }
}

.add-builder-nav, .add-parent-builder{
	.builder-details{
		.header{
			margin-top: 1.5rem;
		}

		.info-popup{
			width: calc(100% - 6.5rem);
			margin-left:2rem;
		}
	}
}



@media only screen and (width <= 1050px) {
    .add-builder-nav{
		/* stylelint-disable-next-line declaration-no-important */
        height:calc(100% - 6rem) !important;
    }

    .add-builder-nav .builder-details{
        padding-top:1rem;
    }

	.add-builder-nav .label{
		font-size: 1.5rem;
	}

    .add-builder-nav .builder-details .navbar-input .input-field{
        .input{
            font-size: 12px;
        }

        select{
            font-size: 12px;
        }

        .radio{
            .radio-option{
				/* stylelint-disable-next-line max-nesting-depth */
                label{
                    font-size: 12px;
                }
            }
        }
    }



	.add-builder-nav .builder-details .info-popup {
	/* stylelint-disable-next-line declaration-no-important */
		width:85% !important;
		margin: 0;
	}


}