<template lang="pug">
.admin-season-selector
	.select Select Season
	template(v-if="loadingSeasons")
		.loading loading seasons...
	template(v-else)
		select(v-model="model" :disabled="disabled")
			option(:value="null" disabled) No Season Selected
			template(v-for="seasonOption, idx in seasonsArray" :key="idx")
				option(:value="seasonOption.$id") {{ seasonOption.name }}
					template(v-if="seasonOption.isCurrent") (Current)
</template>

<script setup lang="ts">
import { Season } from "@busy-human/hxp-library";
import { type DataInstance } from "@busy-human/gearbox";
import { doc, getDoc } from 'firebase/firestore';
import { reactive, ref, watch, computed, onMounted } from 'vue';
import {useSeasons} from '@/store/seasons';
import { useSeasonBuilders } from "@/store/builder-seasons";
import { useAuth } from "@/store/auth";

const seasonStore = useSeasons();
const auth = useAuth();
const builderSeasons = useSeasonBuilders();

// eslint-disable-next-line no-undef
const model = defineModel<string>();

const props = defineProps<{disabled?: boolean}>();

const seasonOptions = ref(new Map());

const loadingSeasons = ref(true);

const seasonsArray = computed(() => [...seasonOptions.value.keys()].map((sId: string) => ({$id: sId, ...seasonOptions.value.get(sId)} as DataInstance<Season.Model>)));

onMounted(() => {
	const seasonsMap = seasonStore.getSeasons();
	if(auth.isAdmin){
		seasonOptions.value = seasonsMap ;
	}else{
		const seasonOptionIds = [...seasonsMap.keys()];
		seasonOptionIds.forEach((s) => {
			if(builderSeasons.accountVisableSeasons.includes(s)){
				seasonOptions.value.set(s, seasonsMap.get(s));
			}
		});
	}
	
	if(!model.value) {
		model.value = seasonStore.current?.$id;
	};
	loadingSeasons.value = false;
});

watch(() => model.value, () => {
	if(model.value) {
		seasonStore.setSeasonOverride(model.value);
		if(seasonStore.current?.isCurrent) seasonStore.clearSeasonOverride();
	}
});

</script>

<style scoped>
.admin-season-selector {
	max-width: 80rem;
    padding: 1rem;
	font-size: 1.5em;
}
</style>